/** @format */
body {
  background-color: $--bg-color;
  font-family: $--main-font;
  font-weight: 400;
  color: $--main-text-color;
}

body.modal-open {
  overflow: hidden;
}

.list {
  list-style: none;
  text-decoration: none;
  margin: 0;
  padding: 0;
}

.container {
  min-width: 320px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 24px;
  padding-right: 24px;

  @media screen and (min-width: $--mobile) {
    width: $--mobile;
  }

  @media screen and (min-width: $--tablet) {
    width: $--tablet;
    padding-left: 40px;
    padding-right: 40px;
  }

  @media screen and (min-width: $--desktop) {
    width: $--desktop;
    padding-left: 100px;
    padding-right: 100px;
  }
}

.img {
  display: block;

  @media screen and (max-width: $--pre-tablet) {
    width: 100%;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

p {
  margin: 0;
}

.svg {
  display: block;
  width: 100%;
  height: 100%;
}

.btn {
  display: inline-block;
  min-width: 163px;
  padding: 16px 40px;

  font-size: 16px;
  font-weight: 700;

  background-color: $--accent-color;
  color: $--main-text-color;
  text-decoration: none;
  transition: color $--cubic-bezier, background-color $--cubic-bezier;

  border-radius: 8px;

  &:hover,
  :focus {
    color: $--second-text-color;
    background-color: #ffdfd7;
  }

  @media screen and (min-width: $--tablet) and (max-width: $--pre-desktop) {
    min-width: 205px;
  }

  @media screen and (min-width: $--desktop) {
    min-width: 187px;
  }
}

.section {
  padding-bottom: 124px;

  @media screen and (min-width: $--tablet) and (max-width: $--pre-desktop) {
    padding-bottom: 160px;
  }

  @media screen and (min-width: $--desktop) {
    padding-bottom: 200px;
  }
}

%hover-link {
  color: $--accent-color;
  transition: color $--cubic-bezier;
}

.tns-liveregion {
  display: none;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;

  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}
