/** @format */

.house-title {
  margin-bottom: 8px;

  font-weight: 400;
  font-size: 40px;
  line-height: 1.1;
  letter-spacing: 0.24px;

  text-transform: uppercase;

  @media screen and (min-width: $--tablet) {
    font-size: 48px;
    line-height: 1.2;
  }

  @media screen and (min-width: $--desktop) {
    margin-bottom: 0;
    margin-right: 5px;
  }
}

.title-box {
  @media screen and (min-width: $--desktop) {
    margin-bottom: 72px;

    display: flex;
    align-items: baseline;
  }
}

.house__pre-title {
  margin-bottom: 32px;

  font-size: 16px;
  line-height: 1.3;
  letter-spacing: 0.48px;

  @media screen and (min-width: $--tablet) {
    margin-bottom: 64px;

    font-size: 18px;
    letter-spacing: 0.45px;
  }

  @media screen and (min-width: $--desktop) {
    margin-bottom: 0;
  }
}

.house__about {
  margin-bottom: 83px;

  font-size: 16px;

  line-height: 1.3;
  letter-spacing: 0.48px;

  @media screen and (min-width: $--tablet) {
    margin-bottom: 77px;
    font-size: 18px;
    letter-spacing: 0.45px;
  }

  @media screen and (min-width: $--desktop) {
    width: 745px;
    margin-bottom: 90px;
  }
}

.house-list {
  display: flex;
  flex-direction: column;
  gap: 69px;

  margin-bottom: 67px;

  @media screen and (min-width: $--tablet) {
    margin-bottom: 25px;

    flex-direction: row;
    gap: 24px;
  }

  @media screen and (min-width: $--desktop) {
    gap: 25px;
  }
}

.house-list__item {
  position: relative;

  width: 342px;
  height: 184px;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 57px;

  background-color: $--main-text-color;
  border-radius: 24px;

  @media screen and (min-width: $--tablet) and (max-width: $--pre-desktop) {
    width: 205px;
    height: 232px;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 49px;
    border-radius: 16px;
  }

  @media screen and (min-width: $--desktop) {
    width: 397px;
    height: 206px;
    padding-left: 32px;
    padding-right: 32px;
    padding-top: 69px;
  }
}

.house-list__icon {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);

  display: block;

  width: 90px;
  height: 87px;
  padding: 18px 10px 18px 11px;

  border-radius: 16px;
  background-color: $--accent-color;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);

  @media screen and (min-width: $--tablet) and (max-width: $--pre-desktop) {
    width: 57px;
    height: 57px;
    padding: 13px 8px;
  }

  @media screen and (min-width: $--desktop) {
    width: 96;
    height: 90px;
    padding: 16px 10px;
  }
}

.house-list__description {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.3;
  letter-spacing: 0.48px;
  color: $--second-text-color;

  @media screen and (min-width: $--tablet) {
    line-height: 1.3;
    letter-spacing: 0.08px;
  }

  @media screen and (min-width: $--desktop) {
    font-size: 18px;

    letter-spacing: 0.45px;
    line-height: 1.3;
  }
}

.house-description__box {
  @media screen and (min-width: $--tablet) {
    margin-bottom: 160px;

    display: flex;
    gap: 24;
  }

  @media screen and (min-width: $--desktop) {
    margin-bottom: 200px;

    gap: 25px;
  }
}

.house-description {
  margin-bottom: 67px;

  width: 342px;
  height: 200px;
  padding: 24px 24px;

  font-size: 16px;
  font-weight: 400;
  line-height: 1.3;
  letter-spacing: 0.48px;

  border-radius: 24px;
  background-color: $--main-text-color;
  color: $--second-text-color;

  &:last-child {
    @media screen and (max-width: $--pre-tablet) {
      margin-bottom: 124px;
    }
    @media screen and (min-width: $--tablet) and (max-width: $--pre-desktop) {
      margin-bottom: 40px;
    }
  }

  @media screen and (min-width: $--tablet) {
    margin-bottom: 0;

    width: 320px;
    height: 200px;
    padding: 26px 16px;

    line-height: 1.3;
    letter-spacing: 0.08px;
  }

  @media screen and (min-width: $--desktop) {
    flex-basis: calc((100% - 25px) / 2);
    height: 160px;
    padding: 32px;

    font-size: 18px;

    letter-spacing: 0.45px;
    line-height: 1.3;
  }
}

.house__sub-title {
  margin-bottom: 48px;

  font-weight: 400;
  font-size: 24px;
  line-height: 1.3;
  letter-spacing: 0.72px;

  @media screen and (min-width: $--tablet) {
    letter-spacing: 0.6px;
  }

  @media screen and (min-width: $--desktop) {
    width: 898px;
  }
}

.house__sub-list {
  @media screen and (max-width: $--pre-desktop) {
    display: flex;
    flex-direction: column;
    gap: 32px;
  }

  @media screen and (min-width: $--desktop) {
    display: flex;
    flex-wrap: wrap;
    gap: 21px;
  }
}

.sub-list__item {
  display: flex;
  align-items: center;

  padding: 16px 8px;

  border-bottom: 1px solid #393838;

  @media screen and (min-width: $--desktop) {
    flex-basis: calc((100% - 21px) / 2);
  }
}

.sub-list__icon {
  flex-shrink: 0;
  margin-right: 24px;
  width: 53px;
  height: 40px;
}

.sub-list__description {
  font-size: 16px;
  line-height: 1.3;
  letter-spacing: 0.48px;

  @media screen and (min-width: $--tablet) {
    font-size: 18px;
    letter-spacing: 0.45px;
  }
}
