/** @format */

.accredited-container {
  overflow: hidden;
}

.accreditet-title {
  margin-bottom: 66px;

  font-weight: 400;
  font-size: 40px;
  line-height: 1.1;
  text-transform: uppercase;

  @media screen and (min-width: $--tablet) {
    margin-bottom: 80px;

    font-size: 48px;
  }

  @media screen and (min-width: $--desktop) {
    margin-bottom: 72px;
  }
}

.accreditet-list {
  display: flex;

  gap: 16px;

  @media screen and (min-width: $--tablet) {
    gap: 24px;
  }

  @media screen and (min-width: $--desktop) {
    gap: 30px;
  }
}

.accredited-list__item {
  overflow: hidden;
  flex-shrink: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  max-width: 163px;
  height: 155px;

  border: 1px solid #393838;
  box-shadow: 0px 0px 68px 0px rgba(0, 0, 0, 0.05);

  @media screen and (min-width: $--tablet) {
    max-width: 205px;
    height: 195px;
  }

  @media screen and (min-width: $--desktop) {
    max-width: 224px;
    height: 195px;
  }
}

.accredited-list__link {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 163px;
  height: 155px;

  padding: 0px 16px;

  cursor: pointer;

  transition: background-color $--cubic-bezier;

  &:hover,
  :focus {
    transition: background-color $--cubic-bezier;
    background-color: $--accent-color;
  }

  @media screen and (min-width: $--tablet) {
    width: 205px;
    height: 195px;
    padding: 69px 16px 68px 16px;
  }

  @media screen and (min-width: $--desktop) {
    width: 224px;
    height: 195px;
  }
}
