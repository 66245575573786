/** @format */
.container-clients {
  overflow: hidden;
}

.clients-title {
  margin-bottom: 60px;

  font-weight: 400;
  font-size: 40px;
  line-height: 1.1;
  text-transform: uppercase;

  @media screen and (min-width: $--tablet) {
    margin-bottom: 64px;
    font-size: 48px;
  }

  @media screen and (min-width: $--desktop) {
    margin-bottom: 72px;
  }
}

.clients-description {
  margin-bottom: 48px;

  font-size: 16px;
  line-height: 1.3;
  letter-spacing: 0.48px;

  @media screen and (min-width: $--desktop) {
    margin-bottom: 40px;
    width: 610px;
    font-size: 18px;
    letter-spacing: 0.45px;
  }
}

.clients-box {
  @media screen and (max-width: $--pre-desktop) {
    display: flex;
    flex-direction: column;
    align-self: center;
  }

  @media screen and (min-width: $--desktop) {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
  }
}

.clients-wrapper {
  overflow: hidden;

  @media screen and (max-width: $--pre-tablet) {
    margin-bottom: 64px;
    height: 330px;
  }

  @media screen and (min-width: $--tablet) and (max-width: $--pre-desktop) {
    margin-bottom: 140px;
    height: 424px;
  }

  @media screen and (min-width: $--desktop) {
    height: 447px;
  }
}

.clients-list {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 16px;
  max-height: 330px;

  @media screen and (min-width: $--tablet) and (max-width: $--pre-desktop) {
    gap: 24px;
    max-height: 434px;
  }

  @media screen and (min-width: $--desktop) {
    gap: 30px;
    max-height: 439px;
  }
}

.clients-list__item {
  flex-basis: 10%;
  flex-shrink: 0;
  max-width: 163px;

  @media screen and (min-width: $--tablet) {
    max-width: 205px;
    height: 198px;
  }

  @media screen and (min-width: $--desktop) {
    max-width: 222px;
    height: 202px;
  }

  border: 1px solid #393838;
  box-shadow: 0px 0px 68px 0px rgba(0, 0, 0, 0.05);
}

.clients-list__link {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 163px;
  height: 155px;
  padding: 0px 16px;

  cursor: pointer;

  transition: background-color $--cubic-bezier;

  @media screen and (min-width: $--tablet) {
    width: 205px;
    height: 198px;
    padding: 69px 16px 68px 16px;
  }

  @media screen and (min-width: $--desktop) {
    width: 222px;
    height: 202px;
  }

  &:hover,
  :focus {
    background-color: $--accent-color;
    transition: background-color $--cubic-bezier;
  }
}

.clients-image {
  flex-shrink: 0;
  margin-left: -24px;
  width: 390px;
  height: 449px;

  @media screen and (min-width: $--tablet) {
    align-self: center;
    width: 576px;
    height: 663px;
  }

  @media screen and (min-width: $--desktop) {
    margin-right: 60px;
    width: 482px;
    height: 663px;
  }

  background-image: url(../images/trust-men.svg);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
