/** @format */
$--bg-color: #040404;
$--main-text-color: #f6f6f6;
$--second-text-color: #030303;
$--accent-color: #ff6036;

$--main-font: "Comfortaa", sans-serif;
$--second-font: "Bruno Ace SC", sans-serif;

$--cubic-bezier: 300ms ease-out;

$--mobile: 390px;
$--pre-tablet: 743px;
$--tablet: 744px;
$--pre-desktop: 1439px;
$--desktop: 1440px;
