/** @format */

body.modal-open {
  overflow: hidden;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background-color: rgba(0, 0, 0, 0.8);

  opacity: 1;
  transition: opacity $--cubic-bezier;
  overflow-y: scroll;
}

.backdrop.is-hidden {
  opacity: 0;
  transition: opacity $--cubic-bezier;
  pointer-events: none;
}

.backdrop.is-hidden .modal {
  transform: translate(-50%, -50%) scale(0);
  transition: transform $--cubic-bezier, scale $--cubic-bezier;
}

.modal-close {
  position: absolute;
  display: block;
  top: 24px;
  right: 16px;

  width: 18px;
  height: 18px;

  border: none;
  padding: 0;
  background-color: inherit;
  cursor: pointer;
  transition: color $--cubic-bezier;
  color: $--second-text-color;

  &:hover,
  :focus {
    color: $--accent-color;
    transition: color $--cubic-bezier;
  }

  @media screen and (min-width: $--tablet) {
    top: 32px;
    right: 32px;
  }
}

.modal {
  overflow-y: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(1);
  transition: transform $--cubic-bezier, scale $--cubic-bezier;

  width: 342px;
  max-height: 90vh;
  padding: 72px 16px 24px 16px;

  color: $--second-text-color;

  background-color: $--main-text-color;

  @media screen and (min-width: $--tablet) {
    width: 664px;
    padding: 62px 80px;
  }

  @media screen and (min-width: $--desktop) {
    width: 608px;
  }
}

.modal__title {
  margin-bottom: 20px;

  text-transform: uppercase;
  font-size: 24px;
  font-weight: 400;
  line-height: 1.2;
  letter-spacing: 0.12px;

  @media screen and (min-width: $--tablet) {
    margin-bottom: 22px;

    font-size: 40px;
    line-height: 1.1;
  }

  @media screen and (min-width: $--desktop) {
    margin-bottom: 24px;

    font-size: 39px;
  }
}

.modal-description {
  margin-bottom: 32px;

  font-size: 16px;
  letter-spacing: 0.45px;
  line-height: 1.2;

  @media screen and (min-width: $--tablet) {
    margin-bottom: 35px;
  }

  @media screen and (min-width: $--desktop) {
    margin-bottom: 43px;
  }
}

.label-field {
  @media screen and (min-width: $--tablet) {
    margin-bottom: 48px;
    display: flex;
    justify-content: space-between;
  }
}

.label-box {
  position: relative;
}

.label-span {
  position: absolute;
  top: -15px;
  left: 16px;

  font-size: 14px;
  font-weight: 300;
  line-height: 1.8;
  letter-spacing: 0.07px;

  color: rgba(141, 141, 141, 0.96);

  @media screen and (min-width: $--tablet) {
    top: -15px;
    left: 16px;
  }

  @media screen and (min-width: $--desktop) {
    top: -17px;
    left: 24px;
  }
}

.input {
  margin-bottom: 32px;
  height: 48px;

  width: 100%;
  height: 100%;

  padding: 14px 16px 15px 16px;
  outline: none;
  color: $--second-text-color;
  background-color: inherit;
  border: none;

  border: none;
  border-radius: 0%;
  border-bottom: 1px solid #c7c7c7;

  transition: border-bottom-color $--cubic-bezier;

  &:focus {
    border-bottom-color: $--accent-color;
    transition: border-bottom-color $--cubic-bezier;
  }

  &:focus.box-input {
    border-bottom-color: $--accent-color;
    transition: border-bottom-color $--cubic-bezier;
  }

  &::placeholder {
    color: $--second-text-color;
  }

  @media screen and (min-width: $--tablet) {
    width: 240px;
    margin-bottom: 0;
  }

  @media screen and (min-width: $--desktop) {
    width: 212px;
    padding: 8px 24px;
  }
}

.label {
  position: relative;
}

.input-number {
  width: 100%;
  margin-bottom: 32px;
  padding: 14px 16px 15px 16px;

  display: block;
  color: $--second-text-color;

  &:focus + .input-line {
    border-bottom-color: $--accent-color;
    transition: border-bottom-color $--cubic-bezier;
  }

  &::placeholder {
    color: $--second-text-color;
  }

  @media screen and (min-width: $--desktop) {
    margin-bottom: 48px;
    padding: 8px 24px;
  }
}

.textrea-box {
  position: relative;
}

.textrea-span {
  display: block;
  position: absolute;
  top: -14px;
  left: 16px;

  font-size: 14px;
  font-weight: 300;
  line-height: 1.8;

  color: rgba(141, 141, 141, 0.96);

  @media screen and (min-width: $--tablet) {
    left: 16px;
  }

  @media screen and (min-width: $--desktop) {
    left: 24px;
  }
}

.textarea {
  margin-bottom: 29px;
  resize: none;

  width: 100%;
  height: 65px;
  padding: 13px 13px 8px 16px;

  font-family: $--main-font;
  color: $--second-text-color;

  background-color: inherit;
  border: none;
  border-radius: 0%;
  border-bottom: 1px solid #c7c7c7;

  outline: none;
  transition: border-bottom-color $--cubic-bezier;

  &:focus {
    border-bottom-color: $--accent-color;
    transition: border-bottom-color $--cubic-bezier;
  }

  &::placeholder {
    color: $--second-text-color;
  }

  @media screen and (min-width: $--tablet) {
    margin-bottom: 32px;
    padding: 13px 13px 8px 16px;
    height: 60px;
  }

  @media screen and (min-width: $--desktop) {
    height: 65px;
    padding: 13px 13px 8px 24px;
  }
}

.checkbox-title {
  margin-bottom: 28px;
  font-size: 18px;
}

.checkbox-field {
  margin-bottom: 44px;

  display: flex;
  justify-content: space-between;
}

.checkbox-box {
  display: flex;
  align-items: center;
  gap: 8px;

  font-size: 14px;
  line-height: 1.2;
  letter-spacing: 0.08px;

  cursor: pointer;

  @media screen and (min-width: $--tablet) {
    gap: 16px;

    font-size: 16px;
  }
}

.checkbox-icon {
  width: 18px;
  height: 18px;

  border-radius: 2px;
  border: 1px solid #ababab;

  .checkbox:checked + & {
    background-color: $--accent-color;
    background-image: url(../images/done.svg);
    background-repeat: no-repeat;
    background-position: center;
    border: inherit;
  }
}

.checkbox {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  clip: rect(0 0 0 0);
  overflow: hidden;
}

.modal-btn {
  width: 100%;
  cursor: pointer;
  border: inherit;
}
