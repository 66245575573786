@import "https://fonts.googleapis.com/css2?family=Bruno+Ace+SC&family=Comfortaa:wght@300;400;700&family=Days+One&display=swap";
body {
  color: #f6f6f6;
  background-color: #040404;
  font-family: Comfortaa, sans-serif;
  font-weight: 400;
}

.list {
  margin: 0;
  padding: 0;
  text-decoration: none;
  list-style: none;
}

.container {
  min-width: 320px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 24px;
  padding-right: 24px;
}

@media screen and (min-width: 390px) {
  .container {
    width: 390px;
  }
}

@media screen and (min-width: 744px) {
  .container {
    width: 744px;
    padding-left: 40px;
    padding-right: 40px;
  }
}

@media screen and (min-width: 1440px) {
  .container {
    width: 1440px;
    padding-left: 100px;
    padding-right: 100px;
  }
}

.img {
  display: block;
}

@media screen and (max-width: 743px) {
  .img {
    width: 100%;
  }
}

h1, h2, h3, h4, h5, h6, p {
  margin: 0;
}

.svg {
  width: 100%;
  height: 100%;
  display: block;
}

.btn {
  color: #f6f6f6;
  background-color: #ff6036;
  border-radius: 8px;
  min-width: 163px;
  padding: 16px 40px;
  font-size: 16px;
  font-weight: 700;
  text-decoration: none;
  transition: color .3s ease-out, background-color .3s ease-out;
  display: inline-block;
}

.btn:hover, .btn :focus {
  color: #030303;
  background-color: #ffdfd7;
}

@media screen and (min-width: 744px) and (max-width: 1439px) {
  .btn {
    min-width: 205px;
  }
}

@media screen and (min-width: 1440px) {
  .btn {
    min-width: 187px;
  }
}

.section {
  padding-bottom: 124px;
}

@media screen and (min-width: 744px) and (max-width: 1439px) {
  .section {
    padding-bottom: 160px;
  }
}

@media screen and (min-width: 1440px) {
  .section {
    padding-bottom: 200px;
  }
}

.footer-contacts__link:hover, .footer-contacts__link :focus, .footer-nav__link:hover, .footer-nav__link :focus, .footer-list__link:hover, .footer-list__link :focus, .about-box:hover > .about-btn, .about-box :focus > .about-btn, .mobile-contacts__link:hover, .mobile-contacts__link :focus, .mobile-list__link:hover, .mobile-list__link :focus, .contact-list__link:hover, .contact-list__link :focus, .nav-list__link:hover, .nav-list__link :focus {
  color: #ff6036;
  transition: color .3s ease-out;
}

.tns-liveregion {
  display: none;
}

.visually-hidden {
  white-space: nowrap;
  -webkit-clip-path: inset(100%);
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  border: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.logo {
  color: #f6f6f6;
  width: 146px;
  height: 58px;
  display: inline-block;
}

.header-container {
  justify-content: space-between;
  padding-top: 32px;
  padding-bottom: 32px;
  display: flex;
  position: relative;
}

@media screen and (min-width: 1440px) {
  .header-container {
    justify-content: space-between;
    align-items: center;
    display: flex;
  }
}

.nav-list {
  display: none;
}

@media screen and (min-width: 1440px) {
  .nav-list {
    gap: 16px;
    padding-left: 57px;
    padding-right: 57px;
    display: flex;
    position: relative;
  }

  .nav-list:after {
    content: " ";
    background-color: #f6f6f6;
    width: .25px;
    height: 42px;
    display: block;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  }

  .nav-list:before {
    content: " ";
    background-color: #f6f6f6;
    width: .25px;
    height: 42px;
    display: block;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }
}

.nav {
  display: none;
}

@media screen and (min-width: 1440px) {
  .nav {
    display: block;
  }
}

.contact-list {
  display: none;
}

@media screen and (min-width: 1440px) {
  .contact-list {
    align-items: center;
    gap: 16px;
    display: flex;
  }
}

.nav-list__item {
  padding: 0 8px;
}

.nav-list__link {
  letter-spacing: .45px;
  color: #f6f6f6;
  padding: 8px 0;
  font-size: 18px;
  line-height: 1.3;
  text-decoration: none;
  transition: color .3s ease-out;
}

.nav-list__link:hover, .nav-list__link :focus {
  transition: color .3s ease-out;
}

.contact-list__link {
  color: #f6f6f6;
  background-color: inherit;
  fill: inherit;
  width: 48px;
  height: 48px;
  padding: 12px 13px;
  transition: color .3s ease-out;
  display: block;
}

.contact-list__link:hover, .contact-list__link :focus {
  transition: color .3s ease-out;
}

.button-box {
  width: 48px;
  margin-left: auto;
  position: relative;
}

@media screen and (min-width: 1440px) {
  .button-box {
    display: none;
  }
}

.mobile-open {
  opacity: 1;
  pointer-events: all;
  background-color: inherit;
  color: #f6f6f6;
  border: none;
  width: 48px;
  height: 48px;
  padding: 3px;
  transition: opacity .3s ease-out;
  position: absolute;
  left: 0;
}

@media screen and (min-width: 1440px) {
  .mobile-open {
    display: none;
  }
}

.mobile-open:hover, .mobile-open :focus {
  color: #ff6036;
  transition: color 10ms;
}

.mobile-close {
  opacity: 0;
  pointer-events: none;
  background-color: inherit;
  color: #f6f6f6;
  border: none;
  width: 48px;
  height: 48px;
  padding: 14px;
  transition: opacity .3s ease-out;
  position: absolute;
}

@media screen and (min-width: 1440px) {
  .mobile-close {
    display: none;
  }
}

.mobile-close:hover, .mobile-close :focus {
  color: #ff6036;
}

.mobile-close.--active {
  opacity: 1;
  pointer-events: all;
  transition: opacity .3s ease-out;
}

.mobile-open.--hidden {
  opacity: 0;
  pointer-events: none;
  transition: opacity .3s ease-out;
}

.mobile-menu {
  pointer-events: none;
  z-index: 1;
  transform-origin: top;
  opacity: 0;
  background-color: #f6f6f6;
  width: 234px;
  height: 459px;
  padding: 32px 24px;
  transition: transform .3s ease-out, opacity .3s ease-out;
  position: absolute;
  top: 122px;
  right: 0;
  transform: scaleY(0);
}

.mobile-menu.is-open {
  pointer-events: all;
  opacity: 1;
  transition: transform .3s ease-out, opacity .3s ease-out;
  transform: scaleY(1);
}

.mobile-list {
  margin-bottom: 93px;
}

.mobile-list__item {
  border-bottom: 1px solid #03030321;
  margin-bottom: 32px;
  padding: 8px;
}

.mobile-list__item:last-child {
  margin-bottom: 0;
}

.mobile-list__link {
  letter-spacing: .48px;
  color: #030303;
  font-size: 16px;
  line-height: 1.3;
  text-decoration: none;
  display: inline-block;
}

.mobile-contacts {
  align-items: center;
  gap: 16px;
  display: flex;
}

.mobile-contact__item {
  padding: 13px 12px;
}

.mobile-contacts__link {
  color: #030303;
  width: 24px;
  height: 22px;
  display: block;
}

@keyframes glowing {
  0% {
    box-shadow: 0 0 20px #ff603680;
  }

  50% {
    box-shadow: 0 0 20px #ff6036e6;
  }

  100% {
    box-shadow: 0 0 20px #d6d6d666;
  }
}

.mobile-btn {
  z-index: 1;
  color: #ff6036;
  background-color: #fff;
  border-radius: 40px;
  width: 72px;
  height: 72px;
  padding: 15px;
  animation: 2s infinite glowing;
  display: inline-block;
  position: fixed;
  bottom: 72px;
  right: 24px;
}

@media screen and (min-width: 744px) {
  .mobile-btn {
    display: none;
  }
}

.icon-phone {
  fill: #f6f6f6;
  stroke: currentColor;
}

.icon-phone-desk {
  fill: #030303;
  stroke: currentColor;
}

.icon-mobile {
  fill: #ff6036;
  transition: transform 50ms ease-out;
  transform: scale(1);
}

.icon-click {
  transition: transform 50ms ease-out;
  transform: scale(1.2);
}

.mobile-btn.--hidden {
  display: none;
}

@media screen and (max-width: 743px) {
  .hero {
    padding-bottom: 124px;
  }
}

@media screen and (min-width: 744px) and (max-width: 1439px) {
  .hero {
    padding-top: 24px;
    padding-bottom: 160px;
  }
}

@media screen and (min-width: 1440px) {
  .hero {
    padding-top: 24px;
    padding-bottom: 200px;
  }
}

.hero-title {
  letter-spacing: 5%;
  text-transform: uppercase;
  font-family: Bruno Ace SC, sans-serif;
  font-size: 56px;
  line-height: 1.1;
}

@media screen and (max-width: 743px) {
  .hero-title {
    width: 338px;
  }
}

@media screen and (min-width: 744px) {
  .hero-title {
    letter-spacing: 1.44px;
    font-size: 72px;
  }
}

@media screen and (min-width: 1440px) {
  .hero-title {
    letter-spacing: 2.56px;
    font-size: 140px;
  }
}

.sub-title {
  letter-spacing: 5%;
  text-transform: uppercase;
  margin-bottom: 48px;
  font-family: Bruno Ace SC, sans-serif;
  font-size: 32px;
  line-height: 1.4;
}

@media screen and (min-width: 744px) {
  .sub-title {
    margin-bottom: 61px;
    font-size: 56px;
  }
}

@media screen and (min-width: 1440px) {
  .sub-title {
    margin-bottom: 64px;
    font-size: 75px;
  }
}

.hero-description {
  margin-bottom: 40px;
  font-size: 16px;
  line-height: 1.4;
}

@media screen and (min-width: 1440px) {
  .hero-description {
    letter-spacing: .45px;
    width: 495px;
    font-size: 18px;
    line-height: 1.3;
  }
}

.hero-btn {
  border: none;
}

@media screen and (max-width: 743px) {
  .hero-btn {
    margin-bottom: 56px;
  }
}

@media screen and (min-width: 744px) and (max-width: 1440px) {
  .hero-btn {
    margin-bottom: 80px;
  }
}

@media screen and (min-width: 1440px) {
  .hero-box {
    justify-content: space-between;
    display: flex;
  }
}

.hero-image {
  background-image: url("truck.cfd2fc0b.svg");
  background-position: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  flex-shrink: 0;
  width: 342px;
  height: 294px;
}

@media screen and (min-width: 744px) and (max-width: 1439px) {
  .hero-image {
    width: 664px;
    height: 571px;
  }
}

@media screen and (min-width: 1440px) {
  .hero-image {
    width: 661px;
    height: 535px;
  }
}

.about-title {
  text-transform: uppercase;
  margin-bottom: 64px;
  font-size: 40px;
  line-height: 1.8;
}

@media screen and (min-width: 744px) {
  .about-title {
    margin-bottom: 80px;
    font-size: 48px;
  }
}

.about-box {
  color: #030303;
  background-color: #f6f6f6;
  border-radius: 16px;
  justify-content: space-between;
  align-items: center;
  width: 335px;
  height: 148px;
  padding: 32px;
  transition: box-shadow .3s ease-out, color .3s ease-out;
  display: flex;
  position: relative;
}

@media screen and (min-width: 744px) and (max-width: 1439px) {
  .about-box {
    width: 651px;
    height: 216px;
    padding: 32px;
  }
}

@media screen and (min-width: 1440px) {
  .about-box {
    border-radius: 24px;
    width: 1240px;
    height: 156px;
    padding: 32px 124px;
  }
}

.about-box.box-open {
  border-radius: 16px 16px 0 0;
}

@media screen and (min-width: 1440px) {
  .about-box.box-open {
    border-radius: 24px 24px 0 0;
  }
}

.about-btn.box-open {
  color: #ff6036;
  transition: transform .3s ease-out, color .3s ease-out;
  transform: rotate(-30deg);
}

.about-wraper {
  margin-bottom: 32px;
  position: relative;
}

@media screen and (min-width: 1440px) {
  .about-wraper {
    margin-bottom: 48px;
  }
}

.about-wraper:nth-child(4) {
  margin-bottom: 64px;
}

@media screen and (min-width: 1440px) {
  .about-wraper:nth-child(4) {
    margin-bottom: 140px;
  }
}

.about__description-box {
  opacity: 0;
  transform-origin: top;
  color: #030303;
  background-color: #f6f6f6;
  padding: 0 32px 16px;
  transition: opacity .3s ease-out, transform .3s ease-out;
  position: absolute;
  overflow: hidden;
  transform: scaleY(0);
}

@media screen and (min-width: 1440px) {
  .about__description-box {
    letter-spacing: .45px;
    padding: 0 291px 71px 124px;
    font-size: 18px;
    line-height: 1.3;
  }
}

.about__description-box.box-open {
  transform-origin: top;
  opacity: 1;
  border-radius: 0 0 24px 24px;
  transition: opacity .3s ease-out, transform .3s ease-out;
  transform: scaleY(1);
}

@media screen and (max-width: 743px) {
  .about__description-box.box-open {
    width: 335px;
  }
}

@media screen and (min-width: 744px) and (max-width: 1439px) {
  .about__description-box.box-open {
    width: 651px;
  }
}

@media screen and (min-width: 1440px) {
  .about__description-box.box-open {
    width: 1240px;
  }
}

.about__sub-title {
  font-size: 24px;
  font-weight: 400;
  line-height: 1.2;
  transition: color .3s ease-out;
}

@media screen and (min-width: 744px) and (max-width: 1439px) {
  .about__sub-title {
    width: 287px;
    font-size: 40px;
  }
}

@media screen and (min-width: 1440px) {
  .about__sub-title {
    text-align: center;
    font-size: 40px;
  }
}

.about-btn {
  color: #030303;
  background-color: inherit;
  cursor: pointer;
  border: none;
  border-radius: 14px;
  flex-shrink: 0;
  width: 34px;
  height: 25px;
  padding: 0;
  transition: transform .3s ease-out, color .3s ease-out;
  transform: rotate(0);
}

@media screen and (min-width: 744px) {
  .about-btn {
    width: 96px;
    height: 84px;
    padding: 21px 19px;
  }
}

.about-list {
  margin-top: 0;
}

@media screen and (max-width: 1439px) {
  .about-list {
    padding-left: 16px;
  }
}

@media screen and (min-width: 1440px) {
  .about-list {
    width: 486px;
    margin-bottom: 24px;
  }
}

.about-description {
  margin-bottom: 16px;
}

.about-description:last-child {
  margin-bottom: 0;
}

.about-image {
  background-image: url("truck-man.68ad43ec.svg");
  background-repeat: no-repeat;
  background-size: contain;
}

@media screen and (max-width: 743px) {
  .about-image {
    width: 342px;
    height: 224px;
  }
}

@media screen and (min-width: 744px) and (max-width: 1439px) {
  .about-image {
    width: 664px;
    height: 437px;
  }
}

@media screen and (min-width: 1440px) {
  .about-image {
    width: 1240px;
    height: 816px;
  }
}

.house-title {
  letter-spacing: .24px;
  text-transform: uppercase;
  margin-bottom: 8px;
  font-size: 40px;
  font-weight: 400;
  line-height: 1.1;
}

@media screen and (min-width: 744px) {
  .house-title {
    font-size: 48px;
    line-height: 1.2;
  }
}

@media screen and (min-width: 1440px) {
  .house-title {
    margin-bottom: 0;
    margin-right: 5px;
  }

  .title-box {
    align-items: baseline;
    margin-bottom: 72px;
    display: flex;
  }
}

.house__pre-title {
  letter-spacing: .48px;
  margin-bottom: 32px;
  font-size: 16px;
  line-height: 1.3;
}

@media screen and (min-width: 744px) {
  .house__pre-title {
    letter-spacing: .45px;
    margin-bottom: 64px;
    font-size: 18px;
  }
}

@media screen and (min-width: 1440px) {
  .house__pre-title {
    margin-bottom: 0;
  }
}

.house__about {
  letter-spacing: .48px;
  margin-bottom: 83px;
  font-size: 16px;
  line-height: 1.3;
}

@media screen and (min-width: 744px) {
  .house__about {
    letter-spacing: .45px;
    margin-bottom: 77px;
    font-size: 18px;
  }
}

@media screen and (min-width: 1440px) {
  .house__about {
    width: 745px;
    margin-bottom: 90px;
  }
}

.house-list {
  flex-direction: column;
  gap: 69px;
  margin-bottom: 67px;
  display: flex;
}

@media screen and (min-width: 744px) {
  .house-list {
    flex-direction: row;
    gap: 24px;
    margin-bottom: 25px;
  }
}

@media screen and (min-width: 1440px) {
  .house-list {
    gap: 25px;
  }
}

.house-list__item {
  background-color: #f6f6f6;
  border-radius: 24px;
  width: 342px;
  height: 184px;
  padding-top: 57px;
  padding-left: 24px;
  padding-right: 24px;
  position: relative;
}

@media screen and (min-width: 744px) and (max-width: 1439px) {
  .house-list__item {
    border-radius: 16px;
    width: 205px;
    height: 232px;
    padding-top: 49px;
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media screen and (min-width: 1440px) {
  .house-list__item {
    width: 397px;
    height: 206px;
    padding-top: 69px;
    padding-left: 32px;
    padding-right: 32px;
  }
}

.house-list__icon {
  background-color: #ff6036;
  border-radius: 16px;
  width: 90px;
  height: 87px;
  padding: 18px 10px 18px 11px;
  display: block;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateY(-50%)translateX(-50%);
  box-shadow: 0 2px 4px #00000040;
}

@media screen and (min-width: 744px) and (max-width: 1439px) {
  .house-list__icon {
    width: 57px;
    height: 57px;
    padding: 13px 8px;
  }
}

@media screen and (min-width: 1440px) {
  .house-list__icon {
    width: 96px;
    height: 90px;
    padding: 16px 10px;
  }
}

.house-list__description {
  letter-spacing: .48px;
  color: #030303;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.3;
}

@media screen and (min-width: 744px) {
  .house-list__description {
    letter-spacing: .08px;
    line-height: 1.3;
  }
}

@media screen and (min-width: 1440px) {
  .house-list__description {
    letter-spacing: .45px;
    font-size: 18px;
    line-height: 1.3;
  }
}

@media screen and (min-width: 744px) {
  .house-description__box {
    gap: 24px;
    margin-bottom: 160px;
    display: flex;
  }
}

@media screen and (min-width: 1440px) {
  .house-description__box {
    gap: 25px;
    margin-bottom: 200px;
  }
}

.house-description {
  letter-spacing: .48px;
  color: #030303;
  background-color: #f6f6f6;
  border-radius: 24px;
  width: 342px;
  height: 200px;
  margin-bottom: 67px;
  padding: 24px;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.3;
}

@media screen and (max-width: 743px) {
  .house-description:last-child {
    margin-bottom: 124px;
  }
}

@media screen and (min-width: 744px) and (max-width: 1439px) {
  .house-description:last-child {
    margin-bottom: 40px;
  }
}

@media screen and (min-width: 744px) {
  .house-description {
    letter-spacing: .08px;
    width: 320px;
    height: 200px;
    margin-bottom: 0;
    padding: 26px 16px;
    line-height: 1.3;
  }
}

@media screen and (min-width: 1440px) {
  .house-description {
    letter-spacing: .45px;
    flex-basis: calc(50% - 12.5px);
    height: 160px;
    padding: 32px;
    font-size: 18px;
    line-height: 1.3;
  }
}

.house__sub-title {
  letter-spacing: .72px;
  margin-bottom: 48px;
  font-size: 24px;
  font-weight: 400;
  line-height: 1.3;
}

@media screen and (min-width: 744px) {
  .house__sub-title {
    letter-spacing: .6px;
  }
}

@media screen and (min-width: 1440px) {
  .house__sub-title {
    width: 898px;
  }
}

@media screen and (max-width: 1439px) {
  .house__sub-list {
    flex-direction: column;
    gap: 32px;
    display: flex;
  }
}

@media screen and (min-width: 1440px) {
  .house__sub-list {
    flex-wrap: wrap;
    gap: 21px;
    display: flex;
  }
}

.sub-list__item {
  border-bottom: 1px solid #393838;
  align-items: center;
  padding: 16px 8px;
  display: flex;
}

@media screen and (min-width: 1440px) {
  .sub-list__item {
    flex-basis: calc(50% - 10.5px);
  }
}

.sub-list__icon {
  flex-shrink: 0;
  width: 53px;
  height: 40px;
  margin-right: 24px;
}

.sub-list__description {
  letter-spacing: .48px;
  font-size: 16px;
  line-height: 1.3;
}

@media screen and (min-width: 744px) {
  .sub-list__description {
    letter-spacing: .45px;
    font-size: 18px;
  }
}

@media screen and (min-width: 1440px) {
  .drivers-container {
    align-items: center;
    display: flex;
  }
}

.drivers-title {
  text-transform: uppercase;
  margin-bottom: 48px;
  font-size: 40px;
  font-weight: 400;
  line-height: 1.1;
}

@media screen and (min-width: 744px) {
  .drivers-title {
    margin-bottom: 64px;
    font-size: 48px;
  }
}

@media screen and (min-width: 1440px) {
  .drivers-title {
    margin-bottom: 72px;
  }
}

.drivers-description {
  letter-spacing: .48px;
  margin-bottom: 48px;
  font-size: 16px;
  line-height: 1.3;
}

@media screen and (min-width: 744px) {
  .drivers-description {
    font-size: 18px;
  }
}

@media screen and (min-width: 1440px) {
  .drivers-description {
    margin-bottom: 40px;
  }
}

.btn-drivers {
  margin-bottom: 80px;
}

@media screen and (min-width: 744px) {
  .btn-drivers {
    margin-bottom: 140px;
  }
}

@media screen and (min-width: 1440px) {
  .btn-drivers {
    margin-bottom: 0;
  }
}

.drivers-image {
  background-image: url("hands.6902029e.svg");
  background-repeat: no-repeat;
  background-size: contain;
  flex-shrink: 0;
  width: 342px;
  height: 171px;
}

@media screen and (min-width: 744px) {
  .drivers-image {
    width: 664px;
    height: 332px;
  }
}

@media screen and (min-width: 1440px) {
  .drivers-image {
    width: 713px;
    height: 355px;
  }
}

.accredited-container {
  overflow: hidden;
}

.accreditet-title {
  text-transform: uppercase;
  margin-bottom: 66px;
  font-size: 40px;
  font-weight: 400;
  line-height: 1.1;
}

@media screen and (min-width: 744px) {
  .accreditet-title {
    margin-bottom: 80px;
    font-size: 48px;
  }
}

@media screen and (min-width: 1440px) {
  .accreditet-title {
    margin-bottom: 72px;
  }
}

.accreditet-list {
  gap: 16px;
  display: flex;
}

@media screen and (min-width: 744px) {
  .accreditet-list {
    gap: 24px;
  }
}

@media screen and (min-width: 1440px) {
  .accreditet-list {
    gap: 30px;
  }
}

.accredited-list__item {
  border: 1px solid #393838;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  max-width: 163px;
  height: 155px;
  display: flex;
  overflow: hidden;
  box-shadow: 0 0 68px #0000000d;
}

@media screen and (min-width: 744px) {
  .accredited-list__item {
    max-width: 205px;
    height: 195px;
  }
}

@media screen and (min-width: 1440px) {
  .accredited-list__item {
    max-width: 224px;
    height: 195px;
  }
}

.accredited-list__link {
  cursor: pointer;
  justify-content: center;
  align-items: center;
  width: 163px;
  height: 155px;
  padding: 0 16px;
  transition: background-color .3s ease-out;
  display: flex;
}

.accredited-list__link:hover, .accredited-list__link :focus {
  background-color: #ff6036;
  transition: background-color .3s ease-out;
}

@media screen and (min-width: 744px) {
  .accredited-list__link {
    width: 205px;
    height: 195px;
    padding: 69px 16px 68px;
  }
}

@media screen and (min-width: 1440px) {
  .accredited-list__link {
    width: 224px;
    height: 195px;
  }
}

.container-clients {
  overflow: hidden;
}

.clients-title {
  text-transform: uppercase;
  margin-bottom: 60px;
  font-size: 40px;
  font-weight: 400;
  line-height: 1.1;
}

@media screen and (min-width: 744px) {
  .clients-title {
    margin-bottom: 64px;
    font-size: 48px;
  }
}

@media screen and (min-width: 1440px) {
  .clients-title {
    margin-bottom: 72px;
  }
}

.clients-description {
  letter-spacing: .48px;
  margin-bottom: 48px;
  font-size: 16px;
  line-height: 1.3;
}

@media screen and (min-width: 1440px) {
  .clients-description {
    letter-spacing: .45px;
    width: 610px;
    margin-bottom: 40px;
    font-size: 18px;
  }
}

@media screen and (max-width: 1439px) {
  .clients-box {
    flex-direction: column;
    align-self: center;
    display: flex;
  }
}

@media screen and (min-width: 1440px) {
  .clients-box {
    flex-direction: row-reverse;
    justify-content: flex-end;
    display: flex;
  }
}

.clients-wrapper {
  overflow: hidden;
}

@media screen and (max-width: 743px) {
  .clients-wrapper {
    height: 330px;
    margin-bottom: 64px;
  }
}

@media screen and (min-width: 744px) and (max-width: 1439px) {
  .clients-wrapper {
    height: 424px;
    margin-bottom: 140px;
  }
}

@media screen and (min-width: 1440px) {
  .clients-wrapper {
    height: 447px;
  }
}

.clients-list {
  flex-flow: column wrap;
  flex-shrink: 0;
  gap: 16px;
  max-height: 330px;
  display: flex;
}

@media screen and (min-width: 744px) and (max-width: 1439px) {
  .clients-list {
    gap: 24px;
    max-height: 434px;
  }
}

@media screen and (min-width: 1440px) {
  .clients-list {
    gap: 30px;
    max-height: 439px;
  }
}

.clients-list__item {
  border: 1px solid #393838;
  flex-shrink: 0;
  flex-basis: 10%;
  max-width: 163px;
  box-shadow: 0 0 68px #0000000d;
}

@media screen and (min-width: 744px) {
  .clients-list__item {
    max-width: 205px;
    height: 198px;
  }
}

@media screen and (min-width: 1440px) {
  .clients-list__item {
    max-width: 222px;
    height: 202px;
  }
}

.clients-list__link {
  cursor: pointer;
  justify-content: center;
  align-items: center;
  width: 163px;
  height: 155px;
  padding: 0 16px;
  transition: background-color .3s ease-out;
  display: flex;
}

@media screen and (min-width: 744px) {
  .clients-list__link {
    width: 205px;
    height: 198px;
    padding: 69px 16px 68px;
  }
}

@media screen and (min-width: 1440px) {
  .clients-list__link {
    width: 222px;
    height: 202px;
  }
}

.clients-list__link:hover, .clients-list__link :focus {
  background-color: #ff6036;
  transition: background-color .3s ease-out;
}

.clients-image {
  background-image: url("trust-men.19714554.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  flex-shrink: 0;
  width: 390px;
  height: 449px;
  margin-left: -24px;
}

@media screen and (min-width: 744px) {
  .clients-image {
    align-self: center;
    width: 576px;
    height: 663px;
  }
}

@media screen and (min-width: 1440px) {
  .clients-image {
    width: 482px;
    height: 663px;
    margin-right: 60px;
  }
}

.footer {
  text-align: center;
  color: #030303;
  background-color: #f6f6f6;
  padding-top: 56px;
  padding-bottom: 88px;
}

@media screen and (min-width: 744px) {
  .footer {
    padding-bottom: 80px;
  }
}

@media screen and (min-width: 1440px) {
  .footer {
    padding-top: 48px;
    padding-bottom: 98px;
  }
}

.footer-title {
  letter-spacing: 2px;
  text-transform: uppercase;
  -webkit-text-stroke: 1px #030303;
  color: #f6f6f6;
  margin-bottom: 40px;
  font-family: Bruno Ace SC, sans-serif;
  font-size: 40px;
  line-height: 1.1;
}

@media screen and (min-width: 744px) {
  .footer-title {
    margin-bottom: 44px;
    font-size: 75px;
  }
}

@media screen and (min-width: 1440px) {
  .footer-title {
    margin-bottom: 0;
  }
}

.footer-list {
  margin-bottom: 48px;
}

@media screen and (min-width: 744px) {
  .footer-list {
    margin-bottom: 88px;
    display: inline-block;
  }
}

@media screen and (min-width: 1440px) {
  .footer-list {
    margin-bottom: 0;
  }
}

.footer-list__item {
  border-bottom: 1px solid #03030321;
  align-items: center;
  margin-bottom: 8px;
  padding: 8px 0;
  display: flex;
}

.footer-list__item:last-child {
  margin-bottom: 0;
}

@media screen and (min-width: 744px) {
  .footer-list__item {
    width: 482px;
    height: 48px;
    margin-bottom: 10px;
    padding: 8px 24px;
  }
}

.footer-list__description {
  letter-spacing: .065px;
  font-size: 13px;
  line-height: 1.2;
}

@media screen and (min-width: 744px) {
  .footer-list__description {
    letter-spacing: .08px;
    font-size: 16px;
  }
}

.footer-list__link {
  letter-spacing: .065px;
  color: inherit;
  margin-left: 8px;
  font-size: 13px;
  line-height: 1.2;
  text-decoration: none;
  transition: color .3s ease-out;
  display: inline-block;
}

@media screen and (min-width: 744px) {
  .footer-list__link {
    letter-spacing: .08px;
    margin-left: 24px;
    font-size: 16px;
  }
}

.footer-list__link:hover, .footer-list__link :focus {
  transition: color .3s ease-out;
}

.footer-nav {
  text-align: center;
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
  margin-bottom: 48px;
  padding: 24px 0;
  position: relative;
}

@media screen and (min-width: 744px) {
  .footer-nav {
    padding: 48px 0;
  }
}

@media screen and (min-width: 1440px) {
  .footer-nav {
    border: none;
  }

  .footer-nav:before {
    content: "";
    border-top: 1px solid #000;
    width: 120%;
    display: block;
    position: absolute;
    top: 0;
    left: -110px;
  }

  .footer-nav:after {
    content: "";
    border-bottom: 1px solid #000;
    width: 120%;
    display: block;
    position: absolute;
    bottom: 0;
    left: -110px;
  }
}

@media screen and (min-width: 744px) {
  .footer-nav__list {
    justify-content: center;
    align-items: center;
    gap: 16px;
    display: flex;
  }
}

.footer-nav__item {
  margin-bottom: 16px;
}

.footer-nav__item :last-child {
  margin-bottom: 0;
}

@media screen and (min-width: 744px) {
  .footer-nav__item {
    margin-bottom: 0;
  }
}

.footer-nav__link {
  letter-spacing: .065px;
  color: inherit;
  padding: 8px;
  font-size: 13px;
  line-height: 1.2;
  text-decoration: none;
  transition: color .3s ease-out;
  display: inline-block;
}

@media screen and (min-width: 744px) {
  .footer-nav__link {
    letter-spacing: .45px;
    font-size: 18px;
    line-height: 1.3;
  }
}

.footer-nav__link:hover, .footer-nav__link :focus {
  transition: color .3s ease-out;
}

.footer-contacts {
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin-bottom: 32px;
  display: flex;
}

@media screen and (min-width: 744px) {
  .footer-contacts {
    gap: 21px;
    margin-bottom: 0;
  }
}

.footer-contacts__link {
  color: #030303;
  width: 74px;
  height: 74px;
  padding: 20px 18px;
  transition: color .3s ease-out;
  display: block;
}

@media screen and (min-width: 744px) {
  .footer-contacts__link {
    width: 48px;
    height: 48px;
    padding: 13px 12px;
  }
}

.footer-contacts__link:hover, .footer-contacts__link :focus {
  transition: color .3s ease-out;
}

.logo-footer {
  color: #030303;
  flex-shrink: 0;
  width: 165px;
  height: 53px;
  margin-bottom: 88px;
  display: inline-block;
}

@media screen and (min-width: 744px) {
  .logo-footer {
    width: 202px;
    height: 66px;
    margin-bottom: 0;
  }
}

.footer-description {
  letter-spacing: .065px;
  font-size: 13px;
  line-height: 1.2;
}

.footer-description:first-of-type {
  margin-bottom: 16px;
}

@media screen and (min-width: 744px) {
  .footer-description {
    font-size: 16px;
    font-weight: 300;
    line-height: 1.1;
  }
}

.footer-tablet__box {
  flex-direction: column-reverse;
  align-items: center;
  display: flex;
}

@media screen and (min-width: 744px) and (max-width: 1439px) {
  .footer-tablet__box {
    flex-direction: row;
    justify-content: space-around;
    margin-bottom: 88px;
  }
}

@media screen and (min-width: 1440px) {
  .footer-tablet__box {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .footer-title__box {
    justify-content: space-between;
    align-items: center;
    margin-bottom: 48px;
    display: flex;
  }

  .footer-line {
    border-left: 1px solid #8d8d8df5;
    width: 62.5px;
    height: 147.5px;
    display: block;
    rotate: -160deg;
  }

  .footer-desktop__box {
    align-items: center;
    display: flex;
  }

  .footer-copyright {
    margin-top: -56px;
  }
}

body.modal-open {
  overflow: hidden;
}

.backdrop {
  opacity: 1;
  background-color: #000c;
  width: 100%;
  height: 100%;
  transition: opacity .3s ease-out;
  position: fixed;
  top: 0;
  left: 0;
  overflow-y: scroll;
}

.backdrop.is-hidden {
  opacity: 0;
  pointer-events: none;
  transition: opacity .3s ease-out;
}

.backdrop.is-hidden .modal {
  transition: transform .3s ease-out, scale .3s ease-out;
  transform: translate(-50%, -50%)scale(0);
}

.modal-close {
  background-color: inherit;
  cursor: pointer;
  color: #030303;
  border: none;
  width: 18px;
  height: 18px;
  padding: 0;
  transition: color .3s ease-out;
  display: block;
  position: absolute;
  top: 24px;
  right: 16px;
}

.modal-close:hover, .modal-close :focus {
  color: #ff6036;
  transition: color .3s ease-out;
}

@media screen and (min-width: 744px) {
  .modal-close {
    top: 32px;
    right: 32px;
  }
}

.modal {
  color: #030303;
  background-color: #f6f6f6;
  width: 342px;
  max-height: 90vh;
  padding: 72px 16px 24px;
  transition: transform .3s ease-out, scale .3s ease-out;
  position: absolute;
  top: 50%;
  left: 50%;
  overflow-y: auto;
  transform: translate(-50%, -50%)scale(1);
}

@media screen and (min-width: 744px) {
  .modal {
    width: 664px;
    padding: 62px 80px;
  }
}

@media screen and (min-width: 1440px) {
  .modal {
    width: 608px;
  }
}

.modal__title {
  text-transform: uppercase;
  letter-spacing: .12px;
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: 400;
  line-height: 1.2;
}

@media screen and (min-width: 744px) {
  .modal__title {
    margin-bottom: 22px;
    font-size: 40px;
    line-height: 1.1;
  }
}

@media screen and (min-width: 1440px) {
  .modal__title {
    margin-bottom: 24px;
    font-size: 39px;
  }
}

.modal-description {
  letter-spacing: .45px;
  margin-bottom: 32px;
  font-size: 16px;
  line-height: 1.2;
}

@media screen and (min-width: 744px) {
  .modal-description {
    margin-bottom: 35px;
  }
}

@media screen and (min-width: 1440px) {
  .modal-description {
    margin-bottom: 43px;
  }
}

@media screen and (min-width: 744px) {
  .label-field {
    justify-content: space-between;
    margin-bottom: 48px;
    display: flex;
  }
}

.label-box {
  position: relative;
}

.label-span {
  letter-spacing: .07px;
  color: #8d8d8df5;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.8;
  position: absolute;
  top: -15px;
  left: 16px;
}

@media screen and (min-width: 744px) {
  .label-span {
    top: -15px;
    left: 16px;
  }
}

@media screen and (min-width: 1440px) {
  .label-span {
    top: -17px;
    left: 24px;
  }
}

.input {
  color: #030303;
  background-color: inherit;
  border: none;
  border-bottom: 1px solid #c7c7c7;
  border-radius: 0%;
  outline: none;
  width: 100%;
  height: 100%;
  margin-bottom: 32px;
  padding: 14px 16px 15px;
  transition: border-bottom-color .3s ease-out;
}

.input:focus, .input:focus.box-input {
  border-bottom-color: #ff6036;
  transition: border-bottom-color .3s ease-out;
}

.input::placeholder {
  color: #030303;
}

@media screen and (min-width: 744px) {
  .input {
    width: 240px;
    margin-bottom: 0;
  }
}

@media screen and (min-width: 1440px) {
  .input {
    width: 212px;
    padding: 8px 24px;
  }
}

.label {
  position: relative;
}

.input-number {
  color: #030303;
  width: 100%;
  margin-bottom: 32px;
  padding: 14px 16px 15px;
  display: block;
}

.input-number:focus + .input-line {
  border-bottom-color: #ff6036;
  transition: border-bottom-color .3s ease-out;
}

.input-number::placeholder {
  color: #030303;
}

@media screen and (min-width: 1440px) {
  .input-number {
    margin-bottom: 48px;
    padding: 8px 24px;
  }
}

.textrea-box {
  position: relative;
}

.textrea-span {
  color: #8d8d8df5;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.8;
  display: block;
  position: absolute;
  top: -14px;
  left: 16px;
}

@media screen and (min-width: 744px) {
  .textrea-span {
    left: 16px;
  }
}

@media screen and (min-width: 1440px) {
  .textrea-span {
    left: 24px;
  }
}

.textarea {
  resize: none;
  color: #030303;
  background-color: inherit;
  border: none;
  border-bottom: 1px solid #c7c7c7;
  border-radius: 0%;
  outline: none;
  width: 100%;
  height: 65px;
  margin-bottom: 29px;
  padding: 13px 13px 8px 16px;
  font-family: Comfortaa, sans-serif;
  transition: border-bottom-color .3s ease-out;
}

.textarea:focus {
  border-bottom-color: #ff6036;
  transition: border-bottom-color .3s ease-out;
}

.textarea::placeholder {
  color: #030303;
}

@media screen and (min-width: 744px) {
  .textarea {
    height: 60px;
    margin-bottom: 32px;
    padding: 13px 13px 8px 16px;
  }
}

@media screen and (min-width: 1440px) {
  .textarea {
    height: 65px;
    padding: 13px 13px 8px 24px;
  }
}

.checkbox-title {
  margin-bottom: 28px;
  font-size: 18px;
}

.checkbox-field {
  justify-content: space-between;
  margin-bottom: 44px;
  display: flex;
}

.checkbox-box {
  letter-spacing: .08px;
  cursor: pointer;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  line-height: 1.2;
  display: flex;
}

@media screen and (min-width: 744px) {
  .checkbox-box {
    gap: 16px;
    font-size: 16px;
  }
}

.checkbox-icon {
  border: 1px solid #ababab;
  border-radius: 2px;
  width: 18px;
  height: 18px;
}

.checkbox:checked + .checkbox-icon {
  border: inherit;
  background-color: #ff6036;
  background-image: url("done.be928898.svg");
  background-position: center;
  background-repeat: no-repeat;
}

.checkbox {
  clip: rect(0 0 0 0);
  border: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.modal-btn {
  cursor: pointer;
  border: inherit;
  width: 100%;
}

/*# sourceMappingURL=index.1a196dcd.css.map */
