/** @format */

.hero {
  @media screen and (max-width: $--pre-tablet) {
    padding-bottom: 124px;
  }

  @media screen and (min-width: $--tablet) and (max-width: $--pre-desktop) {
    padding-top: 24px;
    padding-bottom: 160px;
  }

  @media screen and (min-width: $--desktop) {
    padding-top: 24px;
    padding-bottom: 200px;
  }
}

.hero-title {
  font-size: 56px;
  letter-spacing: 5%;
  line-height: 1.1;

  font-family: $--second-font;
  text-transform: uppercase;

  @media screen and (max-width: $--pre-tablet) {
    width: 338px;
  }

  @media screen and (min-width: $--tablet) {
    font-size: 72px;
    letter-spacing: 1.44px;
  }

  @media screen and (min-width: $--desktop) {
    font-size: 140px;
    letter-spacing: 2.56px;
  }
}

.sub-title {
  margin-bottom: 48px;

  font-family: $--second-font;
  font-size: 32px;
  letter-spacing: 5%;
  line-height: 1.4;

  text-transform: uppercase;

  @media screen and (min-width: $--tablet) {
    margin-bottom: 61px;
    font-size: 56px;
  }

  @media screen and (min-width: $--desktop) {
    margin-bottom: 64px;
    font-size: 75px;
  }
}

.hero-description {
  margin-bottom: 40px;

  font-size: 16px;
  line-height: 1.4;

  @media screen and (min-width: $--desktop) {
    width: 495px;

    font-size: 18px;
    line-height: 1.3;
    letter-spacing: 0.45px;
  }
}

.hero-btn {
  border: none;
  @media screen and (max-width: $--pre-tablet) {
    margin-bottom: 56px;
  }

  @media screen and (min-width: $--tablet) and (max-width: $--desktop) {
    margin-bottom: 80px;
  }
}

.hero-box {
  @media screen and (min-width: $--desktop) {
    display: flex;
    justify-content: space-between;
  }
}

.hero-image {
  flex-shrink: 0;
  width: 342px;
  height: 294px;

  background-image: url(../images/truck.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right;

  @media screen and (min-width: $--tablet) and (max-width: $--pre-desktop) {
    width: 664px;
    height: 571px;
  }

  @media screen and (min-width: $--desktop) {
    width: 661px;
    height: 535px;
  }
}
