@charset "UTF-8";
/** @format */
/** @format */
@import url("https://fonts.googleapis.com/css2?family=Bruno+Ace+SC&family=Comfortaa:wght@300;400;700&family=Days+One&display=swap");
/** @format */
/** @format */
body {
  background-color: #040404;
  font-family: "Comfortaa", sans-serif;
  font-weight: 400;
  color: #f6f6f6;
}

body.modal-open {
  overflow: hidden;
}

.list {
  list-style: none;
  text-decoration: none;
  margin: 0;
  padding: 0;
}

.container {
  min-width: 320px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 24px;
  padding-right: 24px;
}
@media screen and (min-width: 390px) {
  .container {
    width: 390px;
  }
}
@media screen and (min-width: 744px) {
  .container {
    width: 744px;
    padding-left: 40px;
    padding-right: 40px;
  }
}
@media screen and (min-width: 1440px) {
  .container {
    width: 1440px;
    padding-left: 100px;
    padding-right: 100px;
  }
}

.img {
  display: block;
}
@media screen and (max-width: 743px) {
  .img {
    width: 100%;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

p {
  margin: 0;
}

.svg {
  display: block;
  width: 100%;
  height: 100%;
}

.btn {
  display: inline-block;
  min-width: 163px;
  padding: 16px 40px;
  font-size: 16px;
  font-weight: 700;
  background-color: #ff6036;
  color: #f6f6f6;
  text-decoration: none;
  transition: color 300ms ease-out, background-color 300ms ease-out;
  border-radius: 8px;
}
.btn:hover,
.btn :focus {
  color: #030303;
  background-color: #ffdfd7;
}
@media screen and (min-width: 744px) and (max-width: 1439px) {
  .btn {
    min-width: 205px;
  }
}
@media screen and (min-width: 1440px) {
  .btn {
    min-width: 187px;
  }
}

.section {
  padding-bottom: 124px;
}
@media screen and (min-width: 744px) and (max-width: 1439px) {
  .section {
    padding-bottom: 160px;
  }
}
@media screen and (min-width: 1440px) {
  .section {
    padding-bottom: 200px;
  }
}

.footer-contacts__link:hover,
.footer-contacts__link :focus, .footer-nav__link:hover,
.footer-nav__link :focus, .footer-list__link:hover,
.footer-list__link :focus, .about-box:hover > .about-btn,
.about-box :focus > .about-btn, .mobile-contacts__link:hover,
.mobile-contacts__link :focus, .mobile-list__link:hover,
.mobile-list__link :focus, .contact-list__link:hover,
.contact-list__link :focus, .nav-list__link:hover,
.nav-list__link :focus {
  color: #ff6036;
  transition: color 300ms ease-out;
}

.tns-liveregion {
  display: none;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  -webkit-clip-path: inset(100%);
          clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}

/** @format */
.logo {
  display: inline-block;
  width: 146px;
  height: 58px;
  color: #f6f6f6;
}

.header-container {
  position: relative;
  display: flex;
  justify-content: space-between;
  padding-top: 32px;
  padding-bottom: 32px;
}
@media screen and (min-width: 1440px) {
  .header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.nav-list {
  display: none;
}
@media screen and (min-width: 1440px) {
  .nav-list {
    position: relative;
    padding-right: 57px;
    padding-left: 57px;
    display: flex;
    gap: 16px;
  }
  .nav-list::after {
    content: " ";
    position: absolute;
    top: 50%;
    right: 0;
    display: block;
    width: 0.25px;
    height: 42px;
    background-color: #f6f6f6;
    transform: translateY(-50%);
  }
  .nav-list::before {
    content: " ";
    position: absolute;
    top: 50%;
    left: 0;
    display: block;
    width: 0.25px;
    height: 42px;
    background-color: #f6f6f6;
    transform: translateY(-50%);
  }
}

.nav {
  display: none;
}
@media screen and (min-width: 1440px) {
  .nav {
    display: block;
  }
}

.contact-list {
  display: none;
}
@media screen and (min-width: 1440px) {
  .contact-list {
    display: flex;
    align-items: center;
    gap: 16px;
  }
}

.nav-list__item {
  padding: 0 8px;
}

.nav-list__link {
  padding: 8px 0;
  font-size: 18px;
  line-height: 1.3;
  letter-spacing: 0.45px;
  color: #f6f6f6;
  text-decoration: none;
  transition: color 300ms ease-out;
}
.nav-list__link:hover,
.nav-list__link :focus {
  transition: color 300ms ease-out;
}

.contact-list__link {
  display: block;
  width: 48px;
  height: 48px;
  padding: 12px 13px;
  color: #f6f6f6;
  background-color: inherit;
  transition: color 300ms ease-out;
  fill: inherit;
}
.contact-list__link:hover,
.contact-list__link :focus {
  transition: color 300ms ease-out;
}

/** @format */
.button-box {
  position: relative;
  margin-left: auto;
  width: 48px;
}
@media screen and (min-width: 1440px) {
  .button-box {
    display: none;
  }
}

.mobile-open {
  position: absolute;
  opacity: 1;
  left: 0;
  width: 48px;
  height: 48px;
  padding: 3px;
  pointer-events: all;
  background-color: inherit;
  color: #f6f6f6;
  border: none;
  transition: opacity 300ms ease-out;
}
@media screen and (min-width: 1440px) {
  .mobile-open {
    display: none;
  }
}
.mobile-open:hover,
.mobile-open :focus {
  color: #ff6036;
  transition: color 10ms;
}

.mobile-close {
  position: absolute;
  opacity: 0;
  width: 48px;
  height: 48px;
  padding: 14px;
  pointer-events: none;
  background-color: inherit;
  color: #f6f6f6;
  border: none;
  transition: opacity 300ms ease-out;
}
@media screen and (min-width: 1440px) {
  .mobile-close {
    display: none;
  }
}
.mobile-close:hover,
.mobile-close :focus {
  color: #ff6036;
}

.mobile-close.--active {
  opacity: 1;
  pointer-events: all;
  transition: opacity 300ms ease-out;
}

.mobile-open.--hidden {
  opacity: 0;
  pointer-events: none;
  transition: opacity 300ms ease-out;
}

.mobile-menu {
  position: absolute;
  pointer-events: none;
  z-index: 1;
  top: 122px;
  right: 0;
  transform: scaleY(0);
  transform-origin: top;
  opacity: 0;
  padding: 32px 24px;
  width: 234px;
  height: 459px;
  transition: transform 300ms ease-out, opacity 300ms ease-out;
  background-color: #f6f6f6;
}

.mobile-menu.is-open {
  pointer-events: all;
  transform: scaleY(1);
  opacity: 1;
  transition: transform 300ms ease-out, opacity 300ms ease-out;
}

.mobile-list {
  margin-bottom: 93px;
}

.mobile-list__item {
  margin-bottom: 32px;
  padding: 8px;
  border-bottom: 1px solid rgba(3, 3, 3, 0.13);
}
.mobile-list__item:last-child {
  margin-bottom: 0;
}

.mobile-list__link {
  display: inline-block;
  font-size: 16px;
  line-height: 1.3;
  letter-spacing: 0.48px;
  text-decoration: none;
  color: #030303;
}
.mobile-contacts {
  display: flex;
  align-items: center;
  gap: 16px;
}

.mobile-contact__item {
  padding: 13px 12px;
}

.mobile-contacts__link {
  display: block;
  width: 24px;
  height: 22px;
  color: #030303;
}
@keyframes glowing {
  0% {
    box-shadow: 0 0 20px rgba(255, 96, 54, 0.5);
  }
  50% {
    box-shadow: 0 0 20px rgba(255, 96, 54, 0.9);
  }
  100% {
    box-shadow: 0 0 20px rgba(214, 214, 214, 0.4);
  }
}
.mobile-btn {
  position: fixed;
  z-index: 1;
  bottom: 72px;
  right: 24px;
  padding: 15px;
  display: inline-block;
  width: 72px;
  height: 72px;
  border-radius: 40px;
  background-color: #fff;
  color: #ff6036;
  animation: glowing 2000ms infinite;
}
@media screen and (min-width: 744px) {
  .mobile-btn {
    display: none;
  }
}

.icon-phone {
  fill: #f6f6f6;
  stroke: currentColor;
}

.icon-phone-desk {
  fill: #030303;
  stroke: currentColor;
}

.icon-mobile {
  fill: #ff6036;
  transform: scale(1);
  transition: transform 50ms ease-out;
}

.icon-click {
  transform: scale(1.2);
  transition: transform 50ms ease-out;
}

.mobile-btn.--hidden {
  display: none;
}

/** @format */
@media screen and (max-width: 743px) {
  .hero {
    padding-bottom: 124px;
  }
}
@media screen and (min-width: 744px) and (max-width: 1439px) {
  .hero {
    padding-top: 24px;
    padding-bottom: 160px;
  }
}
@media screen and (min-width: 1440px) {
  .hero {
    padding-top: 24px;
    padding-bottom: 200px;
  }
}

.hero-title {
  font-size: 56px;
  letter-spacing: 5%;
  line-height: 1.1;
  font-family: "Bruno Ace SC", sans-serif;
  text-transform: uppercase;
}
@media screen and (max-width: 743px) {
  .hero-title {
    width: 338px;
  }
}
@media screen and (min-width: 744px) {
  .hero-title {
    font-size: 72px;
    letter-spacing: 1.44px;
  }
}
@media screen and (min-width: 1440px) {
  .hero-title {
    font-size: 140px;
    letter-spacing: 2.56px;
  }
}

.sub-title {
  margin-bottom: 48px;
  font-family: "Bruno Ace SC", sans-serif;
  font-size: 32px;
  letter-spacing: 5%;
  line-height: 1.4;
  text-transform: uppercase;
}
@media screen and (min-width: 744px) {
  .sub-title {
    margin-bottom: 61px;
    font-size: 56px;
  }
}
@media screen and (min-width: 1440px) {
  .sub-title {
    margin-bottom: 64px;
    font-size: 75px;
  }
}

.hero-description {
  margin-bottom: 40px;
  font-size: 16px;
  line-height: 1.4;
}
@media screen and (min-width: 1440px) {
  .hero-description {
    width: 495px;
    font-size: 18px;
    line-height: 1.3;
    letter-spacing: 0.45px;
  }
}

.hero-btn {
  border: none;
}
@media screen and (max-width: 743px) {
  .hero-btn {
    margin-bottom: 56px;
  }
}
@media screen and (min-width: 744px) and (max-width: 1440px) {
  .hero-btn {
    margin-bottom: 80px;
  }
}

@media screen and (min-width: 1440px) {
  .hero-box {
    display: flex;
    justify-content: space-between;
  }
}

.hero-image {
  flex-shrink: 0;
  width: 342px;
  height: 294px;
  background-image: url(../images/truck.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right;
}
@media screen and (min-width: 744px) and (max-width: 1439px) {
  .hero-image {
    width: 664px;
    height: 571px;
  }
}
@media screen and (min-width: 1440px) {
  .hero-image {
    width: 661px;
    height: 535px;
  }
}

/** @format */
.about-title {
  margin-bottom: 64px;
  font-size: 40px;
  line-height: 1.8;
  text-transform: uppercase;
}
@media screen and (min-width: 744px) {
  .about-title {
    font-size: 48px;
    margin-bottom: 80px;
  }
}

.about-box {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 335px;
  height: 148px;
  padding: 32px 32px;
  border-radius: 16px 16px 16px 16px;
  background-color: #f6f6f6;
  color: #030303;
  transition: box-shadow 300ms ease-out, color 300ms ease-out;
}
@media screen and (min-width: 744px) and (max-width: 1439px) {
  .about-box {
    width: 651px;
    height: 216px;
    padding: 32px;
  }
}
@media screen and (min-width: 1440px) {
  .about-box {
    width: 1240px;
    height: 156px;
    padding: 32px 124px;
    border-radius: 24px 24px 24px 24px;
  }
}
.about-box.box-open {
  border-radius: 16px 16px 0px 0px;
}
@media screen and (min-width: 1440px) {
  .about-box.box-open {
    border-radius: 24px 24px 0px 0px;
  }
}

.about-btn.box-open {
  transform: rotate(-30deg);
  color: #ff6036;
  transition: transform 300ms ease-out, color 300ms ease-out;
}

.about-wraper {
  position: relative;
  margin-bottom: 32px;
}
@media screen and (min-width: 1440px) {
  .about-wraper {
    margin-bottom: 48px;
  }
}
.about-wraper:nth-child(4) {
  margin-bottom: 64px;
}
@media screen and (min-width: 1440px) {
  .about-wraper:nth-child(4) {
    margin-bottom: 140px;
  }
}

.about__description-box {
  position: absolute;
  overflow: hidden;
  padding: 16px 32px;
  padding-top: 0;
  opacity: 0;
  transform-origin: top;
  transform: scaleY(0);
  transition: opacity 300ms ease-out, transform 300ms ease-out;
  background-color: #f6f6f6;
  color: #030303;
}
@media screen and (min-width: 1440px) {
  .about__description-box {
    padding: 0px 291px 71px 124px;
    font-size: 18px;
    line-height: 1.3;
    letter-spacing: 0.45px;
  }
}

.about__description-box.box-open {
  transform-origin: top;
  opacity: 1;
  transform: scaleY(1);
  transition: opacity 300ms ease-out, transform 300ms ease-out;
  border-radius: 0px 0px 24px 24px;
}
@media screen and (max-width: 743px) {
  .about__description-box.box-open {
    width: 335px;
  }
}
@media screen and (min-width: 744px) and (max-width: 1439px) {
  .about__description-box.box-open {
    width: 651px;
  }
}
@media screen and (min-width: 1440px) {
  .about__description-box.box-open {
    width: 1240px;
  }
}

.about__sub-title {
  font-weight: 400;
  font-size: 24px;
  line-height: 1.2;
  transition: color 300ms ease-out;
}
@media screen and (min-width: 744px) and (max-width: 1439px) {
  .about__sub-title {
    font-size: 40px;
    width: 287px;
  }
}
@media screen and (min-width: 1440px) {
  .about__sub-title {
    text-align: center;
    font-size: 40px;
  }
}

.about-btn {
  flex-shrink: 0;
  width: 34px;
  height: 25px;
  padding: 0;
  border: none;
  border-radius: 14px;
  color: #030303;
  background-color: inherit;
  transform: rotate(0);
  cursor: pointer;
  transition: transform 300ms ease-out, color 300ms ease-out;
}
@media screen and (min-width: 744px) {
  .about-btn {
    width: 96px;
    height: 84px;
    padding: 21px 19px;
  }
}

.about-list {
  margin-top: 0;
}
@media screen and (max-width: 1439px) {
  .about-list {
    padding-left: 16px;
  }
}
@media screen and (min-width: 1440px) {
  .about-list {
    margin-bottom: 24px;
    width: 486px;
  }
}

.about-description {
  margin-bottom: 16px;
}
.about-description:last-child {
  margin-bottom: 0;
}

.about-image {
  background-image: url(../images/truck-man.svg);
  background-size: contain;
  background-repeat: no-repeat;
}
@media screen and (max-width: 743px) {
  .about-image {
    width: 342px;
    height: 224px;
  }
}
@media screen and (min-width: 744px) and (max-width: 1439px) {
  .about-image {
    width: 664px;
    height: 437px;
  }
}
@media screen and (min-width: 1440px) {
  .about-image {
    width: 1240px;
    height: 816px;
  }
}

/** @format */
.house-title {
  margin-bottom: 8px;
  font-weight: 400;
  font-size: 40px;
  line-height: 1.1;
  letter-spacing: 0.24px;
  text-transform: uppercase;
}
@media screen and (min-width: 744px) {
  .house-title {
    font-size: 48px;
    line-height: 1.2;
  }
}
@media screen and (min-width: 1440px) {
  .house-title {
    margin-bottom: 0;
    margin-right: 5px;
  }
}

@media screen and (min-width: 1440px) {
  .title-box {
    margin-bottom: 72px;
    display: flex;
    align-items: baseline;
  }
}

.house__pre-title {
  margin-bottom: 32px;
  font-size: 16px;
  line-height: 1.3;
  letter-spacing: 0.48px;
}
@media screen and (min-width: 744px) {
  .house__pre-title {
    margin-bottom: 64px;
    font-size: 18px;
    letter-spacing: 0.45px;
  }
}
@media screen and (min-width: 1440px) {
  .house__pre-title {
    margin-bottom: 0;
  }
}

.house__about {
  margin-bottom: 83px;
  font-size: 16px;
  line-height: 1.3;
  letter-spacing: 0.48px;
}
@media screen and (min-width: 744px) {
  .house__about {
    margin-bottom: 77px;
    font-size: 18px;
    letter-spacing: 0.45px;
  }
}
@media screen and (min-width: 1440px) {
  .house__about {
    width: 745px;
    margin-bottom: 90px;
  }
}

.house-list {
  display: flex;
  flex-direction: column;
  gap: 69px;
  margin-bottom: 67px;
}
@media screen and (min-width: 744px) {
  .house-list {
    margin-bottom: 25px;
    flex-direction: row;
    gap: 24px;
  }
}
@media screen and (min-width: 1440px) {
  .house-list {
    gap: 25px;
  }
}

.house-list__item {
  position: relative;
  width: 342px;
  height: 184px;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 57px;
  background-color: #f6f6f6;
  border-radius: 24px;
}
@media screen and (min-width: 744px) and (max-width: 1439px) {
  .house-list__item {
    width: 205px;
    height: 232px;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 49px;
    border-radius: 16px;
  }
}
@media screen and (min-width: 1440px) {
  .house-list__item {
    width: 397px;
    height: 206px;
    padding-left: 32px;
    padding-right: 32px;
    padding-top: 69px;
  }
}

.house-list__icon {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  display: block;
  width: 90px;
  height: 87px;
  padding: 18px 10px 18px 11px;
  border-radius: 16px;
  background-color: #ff6036;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
}
@media screen and (min-width: 744px) and (max-width: 1439px) {
  .house-list__icon {
    width: 57px;
    height: 57px;
    padding: 13px 8px;
  }
}
@media screen and (min-width: 1440px) {
  .house-list__icon {
    width: 96;
    height: 90px;
    padding: 16px 10px;
  }
}

.house-list__description {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.3;
  letter-spacing: 0.48px;
  color: #030303;
}
@media screen and (min-width: 744px) {
  .house-list__description {
    line-height: 1.3;
    letter-spacing: 0.08px;
  }
}
@media screen and (min-width: 1440px) {
  .house-list__description {
    font-size: 18px;
    letter-spacing: 0.45px;
    line-height: 1.3;
  }
}

@media screen and (min-width: 744px) {
  .house-description__box {
    margin-bottom: 160px;
    display: flex;
    gap: 24;
  }
}
@media screen and (min-width: 1440px) {
  .house-description__box {
    margin-bottom: 200px;
    gap: 25px;
  }
}

.house-description {
  margin-bottom: 67px;
  width: 342px;
  height: 200px;
  padding: 24px 24px;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.3;
  letter-spacing: 0.48px;
  border-radius: 24px;
  background-color: #f6f6f6;
  color: #030303;
}
@media screen and (max-width: 743px) {
  .house-description:last-child {
    margin-bottom: 124px;
  }
}
@media screen and (min-width: 744px) and (max-width: 1439px) {
  .house-description:last-child {
    margin-bottom: 40px;
  }
}
@media screen and (min-width: 744px) {
  .house-description {
    margin-bottom: 0;
    width: 320px;
    height: 200px;
    padding: 26px 16px;
    line-height: 1.3;
    letter-spacing: 0.08px;
  }
}
@media screen and (min-width: 1440px) {
  .house-description {
    flex-basis: calc((100% - 25px) / 2);
    height: 160px;
    padding: 32px;
    font-size: 18px;
    letter-spacing: 0.45px;
    line-height: 1.3;
  }
}

.house__sub-title {
  margin-bottom: 48px;
  font-weight: 400;
  font-size: 24px;
  line-height: 1.3;
  letter-spacing: 0.72px;
}
@media screen and (min-width: 744px) {
  .house__sub-title {
    letter-spacing: 0.6px;
  }
}
@media screen and (min-width: 1440px) {
  .house__sub-title {
    width: 898px;
  }
}

@media screen and (max-width: 1439px) {
  .house__sub-list {
    display: flex;
    flex-direction: column;
    gap: 32px;
  }
}
@media screen and (min-width: 1440px) {
  .house__sub-list {
    display: flex;
    flex-wrap: wrap;
    gap: 21px;
  }
}

.sub-list__item {
  display: flex;
  align-items: center;
  padding: 16px 8px;
  border-bottom: 1px solid #393838;
}
@media screen and (min-width: 1440px) {
  .sub-list__item {
    flex-basis: calc((100% - 21px) / 2);
  }
}

.sub-list__icon {
  flex-shrink: 0;
  margin-right: 24px;
  width: 53px;
  height: 40px;
}

.sub-list__description {
  font-size: 16px;
  line-height: 1.3;
  letter-spacing: 0.48px;
}
@media screen and (min-width: 744px) {
  .sub-list__description {
    font-size: 18px;
    letter-spacing: 0.45px;
  }
}

/** @format */
@media screen and (min-width: 1440px) {
  .drivers-container {
    display: flex;
    align-items: center;
  }
}

.drivers-title {
  margin-bottom: 48px;
  font-weight: 400;
  font-size: 40px;
  line-height: 1.1;
  text-transform: uppercase;
}
@media screen and (min-width: 744px) {
  .drivers-title {
    margin-bottom: 64px;
    font-size: 48px;
  }
}
@media screen and (min-width: 1440px) {
  .drivers-title {
    margin-bottom: 72px;
  }
}

.drivers-description {
  margin-bottom: 48px;
  font-size: 16px;
  line-height: 1.3;
  letter-spacing: 0.48px;
}
@media screen and (min-width: 744px) {
  .drivers-description {
    font-size: 18px;
  }
}
@media screen and (min-width: 1440px) {
  .drivers-description {
    margin-bottom: 40px;
  }
}

.btn-drivers {
  margin-bottom: 80px;
}
@media screen and (min-width: 744px) {
  .btn-drivers {
    margin-bottom: 140px;
  }
}
@media screen and (min-width: 1440px) {
  .btn-drivers {
    margin-bottom: 0;
  }
}

.drivers-image {
  flex-shrink: 0;
  width: 342px;
  height: 171px;
  background-image: url(../images/hands.svg);
  background-repeat: no-repeat;
  background-size: contain;
}
@media screen and (min-width: 744px) {
  .drivers-image {
    width: 664px;
    height: 332px;
  }
}
@media screen and (min-width: 1440px) {
  .drivers-image {
    width: 713px;
    height: 355px;
  }
}

/** @format */
.accredited-container {
  overflow: hidden;
}

.accreditet-title {
  margin-bottom: 66px;
  font-weight: 400;
  font-size: 40px;
  line-height: 1.1;
  text-transform: uppercase;
}
@media screen and (min-width: 744px) {
  .accreditet-title {
    margin-bottom: 80px;
    font-size: 48px;
  }
}
@media screen and (min-width: 1440px) {
  .accreditet-title {
    margin-bottom: 72px;
  }
}

.accreditet-list {
  display: flex;
  gap: 16px;
}
@media screen and (min-width: 744px) {
  .accreditet-list {
    gap: 24px;
  }
}
@media screen and (min-width: 1440px) {
  .accreditet-list {
    gap: 30px;
  }
}

.accredited-list__item {
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 163px;
  height: 155px;
  border: 1px solid #393838;
  box-shadow: 0px 0px 68px 0px rgba(0, 0, 0, 0.05);
}
@media screen and (min-width: 744px) {
  .accredited-list__item {
    max-width: 205px;
    height: 195px;
  }
}
@media screen and (min-width: 1440px) {
  .accredited-list__item {
    max-width: 224px;
    height: 195px;
  }
}

.accredited-list__link {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 163px;
  height: 155px;
  padding: 0px 16px;
  cursor: pointer;
  transition: background-color 300ms ease-out;
}
.accredited-list__link:hover,
.accredited-list__link :focus {
  transition: background-color 300ms ease-out;
  background-color: #ff6036;
}
@media screen and (min-width: 744px) {
  .accredited-list__link {
    width: 205px;
    height: 195px;
    padding: 69px 16px 68px 16px;
  }
}
@media screen and (min-width: 1440px) {
  .accredited-list__link {
    width: 224px;
    height: 195px;
  }
}

/** @format */
.container-clients {
  overflow: hidden;
}

.clients-title {
  margin-bottom: 60px;
  font-weight: 400;
  font-size: 40px;
  line-height: 1.1;
  text-transform: uppercase;
}
@media screen and (min-width: 744px) {
  .clients-title {
    margin-bottom: 64px;
    font-size: 48px;
  }
}
@media screen and (min-width: 1440px) {
  .clients-title {
    margin-bottom: 72px;
  }
}

.clients-description {
  margin-bottom: 48px;
  font-size: 16px;
  line-height: 1.3;
  letter-spacing: 0.48px;
}
@media screen and (min-width: 1440px) {
  .clients-description {
    margin-bottom: 40px;
    width: 610px;
    font-size: 18px;
    letter-spacing: 0.45px;
  }
}

@media screen and (max-width: 1439px) {
  .clients-box {
    display: flex;
    flex-direction: column;
    align-self: center;
  }
}
@media screen and (min-width: 1440px) {
  .clients-box {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
  }
}

.clients-wrapper {
  overflow: hidden;
}
@media screen and (max-width: 743px) {
  .clients-wrapper {
    margin-bottom: 64px;
    height: 330px;
  }
}
@media screen and (min-width: 744px) and (max-width: 1439px) {
  .clients-wrapper {
    margin-bottom: 140px;
    height: 424px;
  }
}
@media screen and (min-width: 1440px) {
  .clients-wrapper {
    height: 447px;
  }
}

.clients-list {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 16px;
  max-height: 330px;
}
@media screen and (min-width: 744px) and (max-width: 1439px) {
  .clients-list {
    gap: 24px;
    max-height: 434px;
  }
}
@media screen and (min-width: 1440px) {
  .clients-list {
    gap: 30px;
    max-height: 439px;
  }
}

.clients-list__item {
  flex-basis: 10%;
  flex-shrink: 0;
  max-width: 163px;
  border: 1px solid #393838;
  box-shadow: 0px 0px 68px 0px rgba(0, 0, 0, 0.05);
}
@media screen and (min-width: 744px) {
  .clients-list__item {
    max-width: 205px;
    height: 198px;
  }
}
@media screen and (min-width: 1440px) {
  .clients-list__item {
    max-width: 222px;
    height: 202px;
  }
}

.clients-list__link {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 163px;
  height: 155px;
  padding: 0px 16px;
  cursor: pointer;
  transition: background-color 300ms ease-out;
}
@media screen and (min-width: 744px) {
  .clients-list__link {
    width: 205px;
    height: 198px;
    padding: 69px 16px 68px 16px;
  }
}
@media screen and (min-width: 1440px) {
  .clients-list__link {
    width: 222px;
    height: 202px;
  }
}
.clients-list__link:hover,
.clients-list__link :focus {
  background-color: #ff6036;
  transition: background-color 300ms ease-out;
}

.clients-image {
  flex-shrink: 0;
  margin-left: -24px;
  width: 390px;
  height: 449px;
  background-image: url(../images/trust-men.svg);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
@media screen and (min-width: 744px) {
  .clients-image {
    align-self: center;
    width: 576px;
    height: 663px;
  }
}
@media screen and (min-width: 1440px) {
  .clients-image {
    margin-right: 60px;
    width: 482px;
    height: 663px;
  }
}

/** @format */
.footer {
  padding-top: 56px;
  padding-bottom: 88px;
  text-align: center;
  background-color: #f6f6f6;
  color: #030303;
}
@media screen and (min-width: 744px) {
  .footer {
    padding-bottom: 80px;
  }
}
@media screen and (min-width: 1440px) {
  .footer {
    padding-top: 48px;
    padding-bottom: 98px;
  }
}

.footer-title {
  margin-bottom: 40px;
  font-family: "Bruno Ace SC", sans-serif;
  font-size: 40px;
  line-height: 1.1;
  letter-spacing: 2px;
  text-transform: uppercase;
  -webkit-text-stroke: 1px #030303;
  color: #f6f6f6;
}
@media screen and (min-width: 744px) {
  .footer-title {
    margin-bottom: 44px;
    font-size: 75px;
  }
}
@media screen and (min-width: 1440px) {
  .footer-title {
    margin-bottom: 0;
  }
}

.footer-list {
  margin-bottom: 48px;
}
@media screen and (min-width: 744px) {
  .footer-list {
    display: inline-block;
    margin-bottom: 88px;
  }
}
@media screen and (min-width: 1440px) {
  .footer-list {
    margin-bottom: 0;
  }
}

.footer-list__item {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  padding: 8px 0px;
  border-bottom: 1px solid rgba(3, 3, 3, 0.13);
}
.footer-list__item:last-child {
  margin-bottom: 0;
}
@media screen and (min-width: 744px) {
  .footer-list__item {
    margin-bottom: 10px;
    width: 482px;
    height: 48px;
    padding: 8px 24px;
  }
}

.footer-list__description {
  font-size: 13px;
  line-height: 1.2;
  letter-spacing: 0.065px;
}
@media screen and (min-width: 744px) {
  .footer-list__description {
    font-size: 16px;
    letter-spacing: 0.08px;
  }
}

.footer-list__link {
  display: inline-block;
  margin-left: 8px;
  font-size: 13px;
  line-height: 1.2;
  letter-spacing: 0.065px;
  text-decoration: none;
  color: inherit;
  transition: color 300ms ease-out;
}
@media screen and (min-width: 744px) {
  .footer-list__link {
    margin-left: 24px;
    font-size: 16px;
    letter-spacing: 0.08px;
  }
}
.footer-list__link:hover,
.footer-list__link :focus {
  transition: color 300ms ease-out;
}

.footer-nav {
  position: relative;
  margin-bottom: 48px;
  padding: 24px 0;
  text-align: center;
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
}
@media screen and (min-width: 744px) {
  .footer-nav {
    padding: 48px 0;
  }
}
@media screen and (min-width: 1440px) {
  .footer-nav {
    border: none;
  }
  .footer-nav::before {
    content: "";
    display: block;
    width: 120%;
    border-top: 1px solid #000; /* Колір і стиль бордера залишається таким самим */
    position: absolute;
    top: 0;
    left: -110px;
  }
  .footer-nav::after {
    content: "";
    display: block;
    width: 120%;
    border-bottom: 1px solid #000; /* Колір і стиль бордера залишається таким самим */
    position: absolute;
    bottom: 0;
    left: -110px;
  }
}

@media screen and (min-width: 744px) {
  .footer-nav__list {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
  }
}

.footer-nav__item {
  margin-bottom: 16px;
}
.footer-nav__item :last-child {
  margin-bottom: 0;
}
@media screen and (min-width: 744px) {
  .footer-nav__item {
    margin-bottom: 0;
  }
}

.footer-nav__link {
  display: inline-block;
  padding: 8px;
  font-size: 13px;
  line-height: 1.2;
  letter-spacing: 0.065px;
  text-decoration: none;
  color: inherit;
  transition: color 300ms ease-out;
}
@media screen and (min-width: 744px) {
  .footer-nav__link {
    font-size: 18px;
    letter-spacing: 0.45px;
    line-height: 1.3;
  }
}
.footer-nav__link:hover,
.footer-nav__link :focus {
  transition: color 300ms ease-out;
}

.footer-contacts {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin-bottom: 32px;
}
@media screen and (min-width: 744px) {
  .footer-contacts {
    margin-bottom: 0;
    gap: 21px;
  }
}

.footer-contacts__link {
  display: block;
  width: 74px;
  height: 74px;
  padding: 20px 18px;
  color: #030303;
  transition: color 300ms ease-out;
}
@media screen and (min-width: 744px) {
  .footer-contacts__link {
    width: 48px;
    height: 48px;
    padding: 13px 12px;
  }
}
.footer-contacts__link:hover,
.footer-contacts__link :focus {
  transition: color 300ms ease-out;
}

.logo-footer {
  display: inline-block;
  flex-shrink: 0;
  margin-bottom: 88px;
  width: 165px;
  height: 53px;
  color: #030303;
}
@media screen and (min-width: 744px) {
  .logo-footer {
    margin-bottom: 0;
    width: 202px;
    height: 66px;
  }
}

.footer-description {
  font-size: 13px;
  line-height: 1.2;
  letter-spacing: 0.065px;
}
.footer-description:first-of-type {
  margin-bottom: 16px;
}
@media screen and (min-width: 744px) {
  .footer-description {
    font-size: 16px;
    font-weight: 300;
    line-height: 1.1;
  }
}

.footer-tablet__box {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
}
@media screen and (min-width: 744px) and (max-width: 1439px) {
  .footer-tablet__box {
    flex-direction: row;
    justify-content: space-around;
    margin-bottom: 88px;
  }
}
@media screen and (min-width: 1440px) {
  .footer-tablet__box {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
}

@media screen and (min-width: 1440px) {
  .footer-title__box {
    margin-bottom: 48px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

@media screen and (min-width: 1440px) {
  .footer-line {
    display: block;
    width: 62.5px;
    height: 147.5px;
    border-left: 1px solid rgba(141, 141, 141, 0.96);
    rotate: -160deg;
  }
}

@media screen and (min-width: 1440px) {
  .footer-desktop__box {
    display: flex;
    align-items: center;
  }
}

@media screen and (min-width: 1440px) {
  .footer-copyright {
    margin-top: -56px;
  }
}

/** @format */
body.modal-open {
  overflow: hidden;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  opacity: 1;
  transition: opacity 300ms ease-out;
  overflow-y: scroll;
}

.backdrop.is-hidden {
  opacity: 0;
  transition: opacity 300ms ease-out;
  pointer-events: none;
}

.backdrop.is-hidden .modal {
  transform: translate(-50%, -50%) scale(0);
  transition: transform 300ms ease-out, scale 300ms ease-out;
}

.modal-close {
  position: absolute;
  display: block;
  top: 24px;
  right: 16px;
  width: 18px;
  height: 18px;
  border: none;
  padding: 0;
  background-color: inherit;
  cursor: pointer;
  transition: color 300ms ease-out;
  color: #030303;
}
.modal-close:hover,
.modal-close :focus {
  color: #ff6036;
  transition: color 300ms ease-out;
}
@media screen and (min-width: 744px) {
  .modal-close {
    top: 32px;
    right: 32px;
  }
}

.modal {
  overflow-y: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(1);
  transition: transform 300ms ease-out, scale 300ms ease-out;
  width: 342px;
  max-height: 90vh;
  padding: 72px 16px 24px 16px;
  color: #030303;
  background-color: #f6f6f6;
}
@media screen and (min-width: 744px) {
  .modal {
    width: 664px;
    padding: 62px 80px;
  }
}
@media screen and (min-width: 1440px) {
  .modal {
    width: 608px;
  }
}

.modal__title {
  margin-bottom: 20px;
  text-transform: uppercase;
  font-size: 24px;
  font-weight: 400;
  line-height: 1.2;
  letter-spacing: 0.12px;
}
@media screen and (min-width: 744px) {
  .modal__title {
    margin-bottom: 22px;
    font-size: 40px;
    line-height: 1.1;
  }
}
@media screen and (min-width: 1440px) {
  .modal__title {
    margin-bottom: 24px;
    font-size: 39px;
  }
}

.modal-description {
  margin-bottom: 32px;
  font-size: 16px;
  letter-spacing: 0.45px;
  line-height: 1.2;
}
@media screen and (min-width: 744px) {
  .modal-description {
    margin-bottom: 35px;
  }
}
@media screen and (min-width: 1440px) {
  .modal-description {
    margin-bottom: 43px;
  }
}

@media screen and (min-width: 744px) {
  .label-field {
    margin-bottom: 48px;
    display: flex;
    justify-content: space-between;
  }
}

.label-box {
  position: relative;
}

.label-span {
  position: absolute;
  top: -15px;
  left: 16px;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.8;
  letter-spacing: 0.07px;
  color: rgba(141, 141, 141, 0.96);
}
@media screen and (min-width: 744px) {
  .label-span {
    top: -15px;
    left: 16px;
  }
}
@media screen and (min-width: 1440px) {
  .label-span {
    top: -17px;
    left: 24px;
  }
}

.input {
  margin-bottom: 32px;
  height: 48px;
  width: 100%;
  height: 100%;
  padding: 14px 16px 15px 16px;
  outline: none;
  color: #030303;
  background-color: inherit;
  border: none;
  border: none;
  border-radius: 0%;
  border-bottom: 1px solid #c7c7c7;
  transition: border-bottom-color 300ms ease-out;
}
.input:focus {
  border-bottom-color: #ff6036;
  transition: border-bottom-color 300ms ease-out;
}
.input:focus.box-input {
  border-bottom-color: #ff6036;
  transition: border-bottom-color 300ms ease-out;
}
.input::-moz-placeholder {
  color: #030303;
}
.input::placeholder {
  color: #030303;
}
@media screen and (min-width: 744px) {
  .input {
    width: 240px;
    margin-bottom: 0;
  }
}
@media screen and (min-width: 1440px) {
  .input {
    width: 212px;
    padding: 8px 24px;
  }
}

.label {
  position: relative;
}

.input-number {
  width: 100%;
  margin-bottom: 32px;
  padding: 14px 16px 15px 16px;
  display: block;
  color: #030303;
}
.input-number:focus + .input-line {
  border-bottom-color: #ff6036;
  transition: border-bottom-color 300ms ease-out;
}
.input-number::-moz-placeholder {
  color: #030303;
}
.input-number::placeholder {
  color: #030303;
}
@media screen and (min-width: 1440px) {
  .input-number {
    margin-bottom: 48px;
    padding: 8px 24px;
  }
}

.textrea-box {
  position: relative;
}

.textrea-span {
  display: block;
  position: absolute;
  top: -14px;
  left: 16px;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.8;
  color: rgba(141, 141, 141, 0.96);
}
@media screen and (min-width: 744px) {
  .textrea-span {
    left: 16px;
  }
}
@media screen and (min-width: 1440px) {
  .textrea-span {
    left: 24px;
  }
}

.textarea {
  margin-bottom: 29px;
  resize: none;
  width: 100%;
  height: 65px;
  padding: 13px 13px 8px 16px;
  font-family: "Comfortaa", sans-serif;
  color: #030303;
  background-color: inherit;
  border: none;
  border-radius: 0%;
  border-bottom: 1px solid #c7c7c7;
  outline: none;
  transition: border-bottom-color 300ms ease-out;
}
.textarea:focus {
  border-bottom-color: #ff6036;
  transition: border-bottom-color 300ms ease-out;
}
.textarea::-moz-placeholder {
  color: #030303;
}
.textarea::placeholder {
  color: #030303;
}
@media screen and (min-width: 744px) {
  .textarea {
    margin-bottom: 32px;
    padding: 13px 13px 8px 16px;
    height: 60px;
  }
}
@media screen and (min-width: 1440px) {
  .textarea {
    height: 65px;
    padding: 13px 13px 8px 24px;
  }
}

.checkbox-title {
  margin-bottom: 28px;
  font-size: 18px;
}

.checkbox-field {
  margin-bottom: 44px;
  display: flex;
  justify-content: space-between;
}

.checkbox-box {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  line-height: 1.2;
  letter-spacing: 0.08px;
  cursor: pointer;
}
@media screen and (min-width: 744px) {
  .checkbox-box {
    gap: 16px;
    font-size: 16px;
  }
}

.checkbox-icon {
  width: 18px;
  height: 18px;
  border-radius: 2px;
  border: 1px solid #ababab;
}
.checkbox:checked + .checkbox-icon {
  background-color: #ff6036;
  background-image: url(../images/done.svg);
  background-repeat: no-repeat;
  background-position: center;
  border: inherit;
}

.checkbox {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  clip: rect(0 0 0 0);
  overflow: hidden;
}

.modal-btn {
  width: 100%;
  cursor: pointer;
  border: inherit;
}/*# sourceMappingURL=index.css.map */