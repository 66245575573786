/** @format */

.drivers-container {
  @media screen and (min-width: $--desktop) {
    display: flex;
    align-items: center;
  }
}

.drivers-title {
  margin-bottom: 48px;

  font-weight: 400;
  font-size: 40px;
  line-height: 1.1;
  text-transform: uppercase;

  @media screen and (min-width: $--tablet) {
    margin-bottom: 64px;

    font-size: 48px;
  }

  @media screen and (min-width: $--desktop) {
    margin-bottom: 72px;
  }
}

.drivers-description {
  margin-bottom: 48px;

  font-size: 16px;
  line-height: 1.3;
  letter-spacing: 0.48px;

  @media screen and (min-width: $--tablet) {
    font-size: 18px;
  }

  @media screen and (min-width: $--desktop) {
    margin-bottom: 40px;
  }
}

.btn-drivers {
  margin-bottom: 80px;
  @media screen and (min-width: $--tablet) {
    margin-bottom: 140px;
  }

  @media screen and (min-width: $--desktop) {
    margin-bottom: 0;
  }
}

.drivers-image {
  flex-shrink: 0;
  width: 342px;
  height: 171px;

  @media screen and (min-width: $--tablet) {
    width: 664px;
    height: 332px;
  }

  @media screen and (min-width: $--desktop) {
    width: 713px;
    height: 355px;
  }

  background-image: url(../images/hands.svg);
  background-repeat: no-repeat;
  background-size: contain;
}
