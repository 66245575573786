/** @format */

.logo {
  display: inline-block;
  width: 146px;
  height: 58px;

  color: $--main-text-color;
}

.header-container {
  position: relative;
  display: flex;
  justify-content: space-between;

  padding-top: 32px;
  padding-bottom: 32px;

  @media screen and (min-width: $--desktop) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.nav-list {
  display: none;

  @media screen and (min-width: $--desktop) {
    position: relative;
    padding-right: 57px;
    padding-left: 57px;
    display: flex;
    gap: 16px;

    &::after {
      content: " ";
      position: absolute;
      top: 50%;
      right: 0;

      display: block;
      width: 0.25px;
      height: 42px;
      background-color: $--main-text-color;

      transform: translateY(-50%);
    }

    &::before {
      content: " ";
      position: absolute;
      top: 50%;
      left: 0;

      display: block;
      width: 0.25px;
      height: 42px;
      background-color: $--main-text-color;

      transform: translateY(-50%);
    }
  }
}

.nav {
  display: none;

  @media screen and (min-width: $--desktop) {
    display: block;
  }
}

.contact-list {
  display: none;

  @media screen and (min-width: $--desktop) {
    display: flex;
    align-items: center;
    gap: 16px;
  }
}

.nav-list__item {
  padding: 0 8px;
}

.nav-list__link {
  padding: 8px 0;
  font-size: 18px;
  line-height: 1.3;
  letter-spacing: 0.45px;

  color: $--main-text-color;
  text-decoration: none;
  transition: color $--cubic-bezier;

  &:hover,
  :focus {
    @extend %hover-link;

    transition: color $--cubic-bezier;
  }
}

.contact-list__link {
  display: block;
  width: 48px;
  height: 48px;
  padding: 12px 13px;
  color: $--main-text-color;
  background-color: inherit;
  transition: color $--cubic-bezier;
  fill: inherit;
  
  &:hover,
  :focus {
    @extend %hover-link;
    transition: color $--cubic-bezier;
  }
}
