/** @format */

.about-title {
  margin-bottom: 64px;

  font-size: 40px;
  line-height: 1.8;

  text-transform: uppercase;

  @media screen and (min-width: $--tablet) {
    font-size: 48px;
    margin-bottom: 80px;
  }
}

.about-box {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 335px;
  height: 148px;
  padding: 32px 32px;

  border-radius: 16px 16px 16px 16px;
  background-color: $--main-text-color;
  color: $--second-text-color;

  transition: box-shadow $--cubic-bezier, color $--cubic-bezier;

  @media screen and (min-width: $--tablet) and (max-width: $--pre-desktop) {
    width: 651px;
    height: 216px;
    padding: 32px;
  }

  @media screen and (min-width: $--desktop) {
    width: 1240px;
    height: 156px;
    padding: 32px 124px;

    border-radius: 24px 24px 24px 24px;
  }

  &:hover,
  :focus {
    > .about-btn {
      @extend %hover-link;
    }
  }
}

.about-box.box-open {
  border-radius: 16px 16px 0px 0px;

  @media screen and (min-width: $--desktop) {
    border-radius: 24px 24px 0px 0px;
  }
}

.about-btn.box-open {
  transform: rotate(-30deg);
  color: $--accent-color;

  transition: transform $--cubic-bezier, color $--cubic-bezier;
}

.about-wraper {
  position: relative;
  margin-bottom: 32px;

  @media screen and (min-width: $--desktop) {
    margin-bottom: 48px;
  }

  &:nth-child(4) {
    margin-bottom: 64px;
    @media screen and (min-width: $--desktop) {
      margin-bottom: 140px;
    }
  }
}

.about__description-box {
  position: absolute;
  overflow: hidden;

  padding: 16px 32px;
  padding-top: 0;

  opacity: 0;
  transform-origin: top;
  transform: scaleY(0);
  transition: opacity $--cubic-bezier, transform $--cubic-bezier;

  background-color: $--main-text-color;
  color: $--second-text-color;

  @media screen and (min-width: $--desktop) {
    padding: 0px 291px 71px 124px;

    font-size: 18px;
    line-height: 1.3;
    letter-spacing: 0.45px;
  }
}

.about__description-box.box-open {
  transform-origin: top;
  opacity: 1;
  transform: scaleY(1);
  transition: opacity $--cubic-bezier, transform $--cubic-bezier;

  border-radius: 0px 0px 24px 24px;

  @media screen and (max-width: $--pre-tablet) {
    width: 335px;
  }

  @media screen and (min-width: $--tablet) and (max-width: $--pre-desktop) {
    width: 651px;
  }

  @media screen and (min-width: $--desktop) {
    width: 1240px;
  }
}

.about__sub-title {
  font-weight: 400;
  font-size: 24px;
  line-height: 1.2;

  transition: color $--cubic-bezier;

  @media screen and (min-width: $--tablet) and (max-width: $--pre-desktop) {
    font-size: 40px;
    width: 287px;
  }

  @media screen and (min-width: $--desktop) {
    text-align: center;
    font-size: 40px;
  }
}

.about-btn {
  flex-shrink: 0;
  width: 34px;
  height: 25px;

  padding: 0;

  border: none;
  border-radius: 14px;
  color: $--second-text-color;
  background-color: inherit;
  transform: rotate(0);

  cursor: pointer;

  @media screen and (min-width: $--tablet) {
    width: 96px;
    height: 84px;
    padding: 21px 19px;
  }

  transition: transform $--cubic-bezier, color $--cubic-bezier;
}

.about-list {
  margin-top: 0;

  @media screen and (max-width: $--pre-desktop) {
    padding-left: 16px;
  }

  @media screen and (min-width: $--desktop) {
    margin-bottom: 24px;
    width: 486px;
  }
}

.about-description {
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0;
  }
}

.about-image {
  @media screen and (max-width: $--pre-tablet) {
    width: 342px;
    height: 224px;
  }

  @media screen and (min-width: $--tablet) and (max-width: $--pre-desktop) {
    width: 664px;
    height: 437px;
  }

  @media screen and (min-width: $--desktop) {
    width: 1240px;
    height: 816px;
  }

  background-image: url(../images/truck-man.svg);
  background-size: contain;
  background-repeat: no-repeat;
}
