/** @format */

.footer {
  padding-top: 56px;
  padding-bottom: 88px;
  text-align: center;

  @media screen and (min-width: $--tablet) {
    padding-bottom: 80px;
  }

  @media screen and (min-width: $--desktop) {
    padding-top: 48px;
    padding-bottom: 98px;
  }

  background-color: $--main-text-color;
  color: $--second-text-color;
}

.footer-title {
  margin-bottom: 40px;

  font-family: $--second-font;
  font-size: 40px;
  line-height: 1.1;
  letter-spacing: 2px;
  text-transform: uppercase;

  -webkit-text-stroke: 1px $--second-text-color;
  color: $--main-text-color;

  @media screen and (min-width: $--tablet) {
    margin-bottom: 44px;
    font-size: 75px;
  }

  @media screen and (min-width: $--desktop) {
    margin-bottom: 0;
  }
}

.footer-list {
  margin-bottom: 48px;

  @media screen and (min-width: $--tablet) {
    display: inline-block;

    margin-bottom: 88px;
  }

  @media screen and (min-width: $--desktop) {
    margin-bottom: 0;
  }
}

.footer-list__item {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  padding: 8px 0px;

  border-bottom: 1px solid rgba(3, 3, 3, 0.13);

  &:last-child {
    margin-bottom: 0;
  }

  @media screen and (min-width: $--tablet) {
    margin-bottom: 10px;

    width: 482px;
    height: 48px;
    padding: 8px 24px;
  }
}

.footer-list__description {
  font-size: 13px;
  line-height: 1.2;
  letter-spacing: 0.065px;

  @media screen and (min-width: $--tablet) {
    font-size: 16px;
    letter-spacing: 0.08px;
  }
}

.footer-list__link {
  display: inline-block;
  margin-left: 8px;

  font-size: 13px;
  line-height: 1.2;
  letter-spacing: 0.065px;
  text-decoration: none;

  color: inherit;
  transition: color $--cubic-bezier;

  @media screen and (min-width: $--tablet) {
    margin-left: 24px;

    font-size: 16px;
    letter-spacing: 0.08px;
  }

  &:hover,
  :focus {
    @extend %hover-link;
    transition: color $--cubic-bezier;
  }
}

.footer-nav {
  position: relative;

  margin-bottom: 48px;
  padding: 24px 0;

  text-align: center;

  border-top: 1px solid #000;
  border-bottom: 1px solid #000;

  @media screen and (min-width: $--tablet) {
    padding: 48px 0;
  }

  @media screen and (min-width: $--desktop) {
    border: none;
    &::before {
      content: "";
      display: block;
      width: 120%;
      border-top: 1px solid #000; /* Колір і стиль бордера залишається таким самим */
      position: absolute;
      top: 0;
      left: -110px;
    }
    &::after {
      content: "";
      display: block;
      width: 120%;
      border-bottom: 1px solid #000; /* Колір і стиль бордера залишається таким самим */
      position: absolute;
      bottom: 0;
      left: -110px;
    }
  }
}

.footer-nav__list {
  @media screen and (min-width: $--tablet) {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
  }
}

.footer-nav__item {
  margin-bottom: 16px;

  :last-child {
    margin-bottom: 0;
  }

  @media screen and (min-width: $--tablet) {
    margin-bottom: 0;
  }
}

.footer-nav__link {
  display: inline-block;
  padding: 8px;

  font-size: 13px;
  line-height: 1.2;
  letter-spacing: 0.065px;
  text-decoration: none;

  color: inherit;
  transition: color $--cubic-bezier;

  @media screen and (min-width: $--tablet) {
    font-size: 18px;
    letter-spacing: 0.45px;
    line-height: 1.3;
  }

  &:hover,
  :focus {
    @extend %hover-link;
    transition: color $--cubic-bezier;
  }
}

.footer-contacts {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  margin-bottom: 32px;

  @media screen and (min-width: $--tablet) {
    margin-bottom: 0;

    gap: 21px;
  }
}

.footer-contacts__link {
  display: block;
  width: 74px;
  height: 74px;
  padding: 20px 18px;

  color: $--second-text-color;
  transition: color $--cubic-bezier;

  @media screen and (min-width: $--tablet) {
    width: 48px;
    height: 48px;
    padding: 13px 12px;
  }

  &:hover,
  :focus {
    @extend %hover-link;
    transition: color $--cubic-bezier;
  }
}

.logo-footer {
  display: inline-block;
  flex-shrink: 0;
  margin-bottom: 88px;

  width: 165px;
  height: 53px;

  color: $--second-text-color;

  @media screen and (min-width: $--tablet) {
    margin-bottom: 0;
    width: 202px;
    height: 66px;
  }
}

.footer-description {
  font-size: 13px;
  line-height: 1.2;
  letter-spacing: 0.065px;

  &:first-of-type {
    margin-bottom: 16px;
  }
  @media screen and (min-width: $--tablet) {
    font-size: 16px;
    font-weight: 300;
    line-height: 1.1;
  }
}

.footer-tablet__box {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;

  @media screen and (min-width: $--tablet) and (max-width: $--pre-desktop) {
    flex-direction: row;
    justify-content: space-around;

    margin-bottom: 88px;
  }

  @media screen and (min-width: $--desktop) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
}

.footer-title__box {
  @media screen and (min-width: $--desktop) {
    margin-bottom: 48px;

    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.footer-line {
  @media screen and (min-width: $--desktop) {
    display: block;
    width: 62.5px;
    height: 147.5px;
    border-left: 1px solid rgba(141, 141, 141, 0.96);
    rotate: (-160deg);
  }
}

.footer-desktop__box {
  @media screen and (min-width: $--desktop) {
    display: flex;
    align-items: center;
  }
}

.footer-copyright {
  @media screen and (min-width: $--desktop) {
    margin-top: -56px;
  }
}
