/** @format */

.button-box {
  position: relative;
  margin-left: auto;
  width: 48px;

  @media screen and (min-width: $--desktop) {
    display: none;
  }
}

.mobile-open {
  position: absolute;
  opacity: 1;
  left: 0;
  width: 48px;
  height: 48px;
  padding: 3px;

  pointer-events: all;
  background-color: inherit;
  color: $--main-text-color;
  border: none;

  transition: opacity $--cubic-bezier;

  @media screen and (min-width: $--desktop) {
    display: none;
  }

  &:hover,
  :focus {
    color: $--accent-color;
    transition: color 10ms;
  }
}

.mobile-close {
  position: absolute;
  opacity: 0;
  width: 48px;
  height: 48px;
  padding: 14px;

  pointer-events: none;
  background-color: inherit;
  color: $--main-text-color;
  border: none;
  transition: opacity $--cubic-bezier;

  @media screen and (min-width: $--desktop) {
    display: none;
  }

  &:hover,
  :focus {
    color: $--accent-color;
  }
}

.mobile-close.--active {
  opacity: 1;
  pointer-events: all;
  transition: opacity $--cubic-bezier;
}

.mobile-open.--hidden {
  opacity: 0;
  pointer-events: none;
  transition: opacity $--cubic-bezier;
}

.mobile-menu {
  position: absolute;
  pointer-events: none;
  z-index: 1;
  top: 122px;
  right: 0;
  transform: scaleY(0);
  transform-origin: top;
  opacity: 0;

  padding: 32px 24px;
  width: 234px;
  height: 459px;

  transition: transform $--cubic-bezier, opacity $--cubic-bezier;

  background-color: $--main-text-color;
}

.mobile-menu.is-open {
  pointer-events: all;
  transform: scaleY(1);
  opacity: 1;
  transition: transform $--cubic-bezier, opacity $--cubic-bezier;
}

.mobile-list {
  margin-bottom: 93px;
}

.mobile-list__item {
  margin-bottom: 32px;
  padding: 8px;

  border-bottom: 1px solid rgba(3, 3, 3, 0.13);

  &:last-child {
    margin-bottom: 0;
  }
}

.mobile-list__link {
  display: inline-block;

  font-size: 16px;
  line-height: 1.3;
  letter-spacing: 0.48px;
  text-decoration: none;
  color: $--second-text-color;

  &:hover,
  :focus {
    @extend %hover-link;
  }
}

.mobile-contacts {
  display: flex;
  align-items: center;
  gap: 16px;
}

.mobile-contact__item {
  padding: 13px 12px;
}

.mobile-contacts__link {
  display: block;

  width: 24px;
  height: 22px;

  color: $--second-text-color;

  &:hover,
  :focus {
    @extend %hover-link;
  }
}

@keyframes glowing {
  0% {
    box-shadow: 0 0 20px rgba(255, 96, 54, 0.5);
  }
  50% {
    box-shadow: 0 0 20px rgba(255, 96, 54, 0.9);
  }
  100% {
    box-shadow: 0 0 20px rgba(214, 214, 214, 0.4);
  }
}

.mobile-btn {
  position: fixed;
  z-index: 1;
  bottom: 72px;
  right: 24px;
  padding: 15px;

  display: inline-block;

  width: 72px;
  height: 72px;

  border-radius: 40px;
  background-color: #fff;
  color: $--accent-color;
  animation: glowing 2000ms infinite;

  @media screen and (min-width: $--tablet) {
    display: none;
  }
}

.icon-phone {
  fill: $--main-text-color;
  stroke: currentColor;
}

.icon-phone-desk {
  fill: $--second-text-color;
  stroke: currentColor;
}

.icon-mobile {
  fill: $--accent-color;
  transform: scale(1);
  transition: transform 50ms ease-out;
}

.icon-click {
  transform: scale(1.2);
  transition: transform 50ms ease-out;
}

.mobile-btn.--hidden {
  display: none;
}
